import React, { Component } from "react";
import styled from "@emotion/styled";

import Input from "../form/Input";

const Root = styled("div")``;

const Row = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0;
`;

class Video extends Component {
  onChangeSource = value => {
    this.props.onChange("source", value);
  };
  onChangePlaceholderSource = value => {
    this.props.onChange("placeholderSource", value);
  };

  render() {
    const { element } = this.props;

    return (
      <Root>
        <Row>
          <Input
            clearable={true}
            value={element.atn.source}
            onChange={this.onChangeSource}
            placeholder="https://link.to/video.mp4"
            inputType="text"
          />
        </Row>
        <Row>
          <Input
            clearable={true}
            value={element.atn.placeholderSource}
            onChange={this.onChangePlaceholderSource}
            placeholder="https://link.to/video_vorschau.jpg"
            inputType="text"
          />
        </Row>
      </Root>
    );
  }
}

export default Video;
