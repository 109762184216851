import React from "react";
import styled from "@emotion/styled";

import AlignmentPicker from "../style-form/AlignmentPicker";

const Root = styled("div")`
  margin: 0;
`;

const Signature = props => {
  return (
    <Root>
      <AlignmentPicker
        label="Positionierung"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
    </Root>
  );
};

export default Signature;
