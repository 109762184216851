import React, { useState } from "react";
import styled from "@emotion/styled";

const Root = styled("div")`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const Content = styled("div")`
  max-width: 500px;
`;

function isInternetExplorer() {
  //https://stackoverflow.com/questions/24861073/detect-if-any-kind-of-ie-msie/24861307
  return /Trident|MSIE/.test(navigator.userAgent);
}

const OldBrowserNotice = () => {
  const [clickedAway, setClickedAway] = useState(false);

  if (clickedAway || !isInternetExplorer()) return null;

  return (
    <Root>
      <Content>
        <h2>Sie benutzen einen alten Browser.</h2>
        <p>Internet Explorer kann nicht mehr vollständig unterstützt werden.</p>
        <p>
          Wir können nicht garantieren, dass sich das Dokument ordnungsgemäß
          bearbeiten lässt.
        </p>
        <p>
          Bitte laden sie sich einen Browser, wie z.b. Firefox oder Chrome, in
          der aktuellen Version herunter.
        </p>
        <p>Wenn Sie möchten, können Sie auf eigene Gefahr fortfahren.</p>
        <button onClick={() => setClickedAway(true)}>
          Ich kenne das Risiko
        </button>
      </Content>
    </Root>
  );
};

export default OldBrowserNotice;
