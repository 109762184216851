// @flow

import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";

import type { StateType as SettingsType } from "../../reducers/settingsReducer";

import { AVAILABLE_LANGUAGES } from "../../atnConfig";
import { setSetting, addLanguage, removeLanguage } from "../../actions";

import IconButton from "../form/IconButton";
import FaIcon from "../main/FaIcon";

const Languages = styled("div")`
  flex-shrink: 0;
  margin: 0 10px;
  padding: 1px 2px 0px 12px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Language = styled("div")`
  text-align: center;
  padding: 5px 6px;
  margin: 0 2px;
  width: 34px;
  background-color: ${({ active }) => (active ? "#024761" : "white")};
  font-weight: ${({ active }) => (active ? "700" : "300")};
  color: ${({ active }) => (active ? "white" : "#555")};
  border: ${({ active }) => (active ? "1px solid #024761" : "1px solid #ccc")};
  border-radius: 5px;
  transition: background-color 0.1s;
  cursor: pointer;

  &:hover {
    background-color: ${({ active }) => (active ? "#024761" : "#f0f0f0")};
  }
`;

const Select = styled("div")`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 5;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
  overflow: hidden;
`;

const SelectOption = styled("div")`
  padding: 3px 20px;
  border-bottom: 1px solid #ccc;
  color: ${({ active }) => (active ? "white" : "#333")};
  background-color: ${({ active }) => (active ? "#015270" : "white")};
  cursor: pointer;
  transition: background-color 0.1s;
  &:last-of-type {
    border-bottom: none;
  }
  &:hover {
    background-color: ${({ active }) => (active ? "#116280" : "#eee")};
  }
`;

const StyledFaIcon = styled(FaIcon)`
  font-size: 25px;
`;

const DropdownBtn = styled(IconButton)`
  font-size: 20px;
`;

type PropsType = {
  onSetSetting: (string, any) => void,
  onAddLanguage: string => void,
  onRemoveLanguage: string => void,
  settings: SettingsType
};

type StateType = {
  selectOpen: boolean
};

class LanguageSelect extends React.Component<PropsType, StateType> {
  state = {
    selectOpen: false
  };

  render() {
    const {
      onSetSetting,
      onAddLanguage,
      onRemoveLanguage,
      settings: { languages, activeLang }
    } = this.props;

    const { selectOpen } = this.state;

    return (
      <Languages>
        <StyledFaIcon left icon="globe-europe" />
        {languages.map(lang => (
          <Language
            data-testid="language-select-btn"
            key={lang}
            active={lang === activeLang}
            onClick={() => onSetSetting("activeLang", lang)}
          >
            {lang}
          </Language>
        ))}
        <DropdownBtn
          data-testid="language-dropdown-btn"
          onClick={() => this.setState({ selectOpen: !selectOpen })}
        >
          <StyledFaIcon center icon="caret-down" />
        </DropdownBtn>
        {selectOpen && (
          <Select data-testid="language-dropdown">
            {AVAILABLE_LANGUAGES.map(lang => {
              const isActive = languages.includes(lang);

              return (
                <SelectOption
                  key={lang}
                  active={isActive}
                  onClick={() => {
                    if (!isActive) {
                      onAddLanguage(lang);
                    } else {
                      onRemoveLanguage(lang);
                    }

                    this.setState({ selectOpen: false });
                  }}
                >
                  {lang}
                </SelectOption>
              );
            })}
          </Select>
        )}
      </Languages>
    );
  }
}

export default connect(
  state => ({
    settings: state.settings
  }),
  dispatch => ({
    onSetSetting: (attr, value) => dispatch(setSetting(attr, value)),
    onAddLanguage: lang => dispatch(addLanguage(lang)),
    onRemoveLanguage: lang => dispatch(removeLanguage(lang))
  })
)(LanguageSelect);
