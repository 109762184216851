import React from "react";
import styled from "@emotion/styled";

const Icon = styled("i")`
  display: inline-block;
  margin-right: ${({ left }) => (left ? "10px" : "0")};
  margin-left: ${({ right }) => (right ? "10px" : "0")};
  text-align: ${({ center }) => (center ? "center" : "left")};
  font-size: ${({ size, fontSize }) =>
    fontSize ? fontSize : size === "L" ? "16px" : "14px"};
  color: ${({ white, light }) => (white ? "#fff" : light ? "#bbb" : "#333")};
`;

export default ({ icon, className, ...restProps }) => (
  <Icon className={`fa fa-${icon} ${className}`} {...restProps} />
);
