import styled from "@emotion/styled";

const ActiveLang = styled("span")`
  position: absolute;
  top: -12px;
  right: -20px;
  color: #666;
  background-color: white;
  padding: 0 6px;
  border-radius: 3px;
`;

export default ActiveLang;
