import React from "react";
import styled from "@emotion/styled";

const Container = styled("div")`
  display: inline-block;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  font-size: 22px;
  width: 25px;
  height: 25px;
  border: 2px solid #024761;
  border-radius: 50%;
  box-sizing: content-box;
`;

const Checkmark = styled("div")`
  position: absolute;
  top: 3px;
  left: 3px;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  background-color: #024761;
`;

const PreviewRadioButton = ({ className, checked }) => (
  <Container className={className}>{checked && <Checkmark />}</Container>
);

export default PreviewRadioButton;
