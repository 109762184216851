// @flow

import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import Hotkeys from "react-hot-keys";

import { buildATN } from "../../helpers/atnRenderer";
import { saveDocumentTemplate } from "../../actions";
import { selectElementsInOrder } from "../../selectors";
import Spinner from "../styled/Spinner";

import BasicButton from "./BasicButton";

const Prefix = styled("span")`
  display: inline-block;
  width: 22px;
`;

class SaveButton extends React.Component {
  onSaveDocumentTemplate = () => {
    debugger
    const { title, elements, settings } = this.props;

    this.props.saveDocumentTemplate(title, elements, settings);
  };

  render() {
    const { className, loading } = this.props;

    return (
      <BasicButton
        className={className}
        size="L"
        onClick={this.onSaveDocumentTemplate}
      >
        <Prefix>{loading ? <Spinner /> : "💾"}</Prefix> Speichern
        <Hotkeys keyName="alt+s" onKeyUp={this.onSaveDocumentTemplate} />
      </BasicButton>
    );
  }
}

export default connect(
  state => ({
    title: state.documentHeader.title,
    loading: state.documentStatus.docSave.loading,
    elements: selectElementsInOrder(state),
    settings: state.settings
  }),
  dispatch => ({
    saveDocumentTemplate: (title, elements, settings) =>
      dispatch(
        saveDocumentTemplate(
          title,
          buildATN(elements, settings),
          settings.languages
        )
      )
  })
)(SaveButton);
