// @flow

export type StateType = {
  title: string,
  category: ?number
};

export const initialState: StateType = {
  title: "Neues Dokument-Template",
  category: null
};

function reducer(state: StateType = initialState, action: Object): StateType {
  switch (action.type) {
    case "DOCUMENT_HEADER_SET":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default reducer;
