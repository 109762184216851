// @flow

import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const Root = styled("div")`
  color: #666;
`;

const getPreviewText = (element, activeLang) => {
  let text = "";

  if (element.atn.label) {
    text = element.atn.label[activeLang];
  } else if (element.atn.content) {
    text = element.atn.content[activeLang];
  } else if (element.atn.caption) {
    text = element.atn.caption[activeLang];
  } else if (element.atn.source) {
    text = element.atn.source;
  }

  return text.length > 30 ? text.slice(0, 29) + "..." : text;
};

const ContentPreview = ({ element, activeLang }) => {
  return <Root>{getPreviewText(element, activeLang)}</Root>;
};

export default connect(state => ({ activeLang: state.settings.activeLang }))(
  ContentPreview
);
