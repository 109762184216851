import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";

import FaIcon from "../main/FaIcon";
import ComponentTitle from "../styled/ComponentTitle";

import AreaEmpty from "./AreaEmpty";
import StyleEditorElement from "./StyleEditorElement";

const Root = styled("div")`
  background-color: #f3f3f3;
  padding: 0 0 14px;
`;

const EditArea = styled("div")`
  padding: 0px;
`;

const StyledAreaEmpty = styled(AreaEmpty)`
  padding: 20px;
`;

const StyledComponentTitle = styled(ComponentTitle)`
  padding: 12px 20px;
`;

class StyleEditor extends React.PureComponent {
  render() {
    const { activeId, className } = this.props;

    return (
      <Root className={className}>
        {activeId === null && (
          <>
            <StyledComponentTitle>
              <FaIcon left size="L" icon="sliders-h" />
              Stileigenschaften
            </StyledComponentTitle>
            <StyledAreaEmpty>Element auswählen</StyledAreaEmpty>
          </>
        )}
        {activeId !== null && (
          <EditArea>
            <StyleEditorElement elementId={activeId} />
          </EditArea>
        )}
      </Root>
    );
  }
}

export default connect(state => ({
  activeId: state.activeElement.id
}))(StyleEditor);
