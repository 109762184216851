// @flow

import * as React from "react";
import styled from "@emotion/styled";

const Root = styled("button")`
  padding: ${({ size }) => (size === "L" ? "10px 20px" : "5px 15px")};
  font-size: ${({ size }) => (size === "L" ? "18px" : "14px")};

  color: #333;
  border: ${({ noBorder }) =>
    noBorder ? "1px solid transparent" : "1px solid #bbb"};
  border-radius: 5px;
  background-color: white;
  transition: background-color 0.1s;
  line-height: 1;
  outline-width: 0;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;

type PropsType = {
  children: React.Node,
  size?: string
};

const BasicButton = ({ children, ...props }: PropsType) => {
  return (
    <Root type="button" {...props}>
      {children}
    </Root>
  );
};

export default BasicButton;
