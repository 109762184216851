import React from "react";
import styled from "@emotion/styled";

import OrderPicker from "../style-form/OrderPicker";

const Root = styled("div")`
  margin: 0;
`;

const List = props => {
  return (
    <Root>
      <OrderPicker
        label="Listen-Typ"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
    </Root>
  );
};

export default List;
