// @flow

export const isEmpty = (variable: any): boolean => {
  return (
    typeof variable === "undefined" ||
    variable === null ||
    variable === "" ||
    (variable instanceof Array && variable.length === 0) ||
    (variable.constructor === Object && Object.keys(variable).length === 0)
  );
};

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
// export function uuidv4(): string {
//   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
//     var r = (Math.random() * 16) | 0,
//       v = c == "x" ? r : (r & 0x3) | 0x8;
//     return v.toString(16);
//   });
// }
export function uuidv4(): string {
  const uuidStr = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );

  return "id_" + uuidStr.replace(/-/g, "_");
}

export const objectWithoutKey = (key: string) => (obj: Object) => {
  if (!obj.hasOwnProperty(key)) return obj;

  const { [key]: deleted, ...rest } = obj;

  return rest;
};

export const compose = (...fns: Function[]) =>
  fns.reduceRight(
    (prevFn, nextFn) => (...args) => nextFn(prevFn(...args)),
    v => v
  );

export function debounce(func, wait) {
  let timeout;
  return function() {
    let context = this;
    let args = arguments;
    let later = function() {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
