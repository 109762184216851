// @flow

export type StateType = {
  id: ?string,
  nestedInKey: ?string
};

export const initialState: StateType = {
  id: null,
  nestedInKey: null
};

function reducer(state: StateType = initialState, action: Object): StateType {
  switch (action.type) {
    case "ACTIVE_ELEMENT_SET":
      return action.payload.id
        ? {
            ...state,
            id: action.payload.id,
            nestedInKey: action.payload.nestedInKey
          }
        : initialState;
    case "ELEMENT_REMOVE":
      return action.payload.id === state.id ? initialState : state;
    default:
      return state;
  }
}

export default reducer;
