// @flow

import React from "react";

import RadioButton from "../form/RadioButton";
import StylePicker from "./StylePicker";

type PropsType = {
  label: string,
  value: { size: string },
  onChange: (string, string) => void
};

const HeadingSizePicker = (props: PropsType) => {
  return (
    <StylePicker label={props.label}>
      <RadioButton
        value={props.value.headingSize}
        options={[
          { label: "H1", value: 1 },
          { label: "H2", value: 2 },
          { label: "H3", value: 3 },
          { label: "H4", value: 4 },
          { label: "H5", value: 5 }
        ]}
        onChange={value => props.onChange("headingSize", value)}
      />
    </StylePicker>
  );
};

export default HeadingSizePicker;
