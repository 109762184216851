export default [
  "#fc5c65",
  "#fd9644",
  "#f7b731",
  "#26de81",
  "#2bcbba",
  "#45aaf2",
  "#4b7bec",
  "#a55eea",
  "#45aad2",
  "#673831",
  "#555555",
  "#FF1493",
  "#2E8B57",
  "#FFA500",
  "#888888"
];
