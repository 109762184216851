// @flow

import React, { Component } from "react";
import styled from "@emotion/styled";

import LabeledInlineElement from "../form/LabeledInlineElement";
import ToggleButton from "../form/ToggleButton";
import Input from "../form/Input";
import IconButton from "../form/IconButton";
import RequiredInput from "../form/RequiredInput";
import ActiveLang from "../styled/ActiveLang";
import AlternativeInput from "../styled/AlternativeInput";

import FaIcon from "../main/FaIcon";

import { placeholder } from "../../helpers/elementHelper";

const Root = styled("div")`
  width: 100%;
`;

const TextareaInputIndication = styled("div")`
  border: 2px dashed #ccc;
  padding: 5px;
  margin: 5px 0;
  width: 100%;
  height: 100px;
`;

const StyledInput = styled(Input)`
  ${AlternativeInput};
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledToggleButton = styled(ToggleButton)`
  margin: 0 10px 0 0;
`;

const ADD_TO_ANAMNESIS_REPORT_CONTAINER_STYLE = { marginTop: "5px" };

class TextareaInput extends Component {
  onChangeAttr = (attr, value) => {
    this.props.onChange(attr, {
      ...this.props.element.atn[attr],
      [this.props.settings.activeLang]: value
    });
  };

  onChangePlaceholder = value => {
    this.onChangeAttr("placeholder", value);
  };

  onChangeLabel = value => {
    this.onChangeAttr("label", value);
  };

  onChangeAllowCameraInput = value => {
    this.props.onChange("allowCameraInput", value);
  };

  onChangeAnamnesisReportLabel = value => {
    this.props.onChange("anamnesisReportLabel", value);
  };

  onToggleAnamnesisReport = () => {
    this.props.onChange(
      "addToAnamnesisReport",
      !this.props.element.atn.addToAnamnesisReport
    );
    this.props.onChange("anamnesisReportLabel", null);
  };

  render() {
    const {
      element,
      onChange,
      multiLanguage,
      settings: { activeLang }
    } = this.props;

    return (
      <Root>
        <Row>
          <Input
            key={activeLang}
            inline={true}
            clearable={true}
            value={element.atn.label[activeLang]}
            onChange={this.onChangeLabel}
            placeholder={placeholder(this.props, "label", "Bezeichnung")}
            inputType="text"
            append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
          />
          <IconButton tabIndex="-1" onClick={this.onToggleAnamnesisReport}>
            <FaIcon center light icon="file-alt" />
          </IconButton>
        </Row>
        {element.atn.addToAnamnesisReport && (
          <StyledInput
            size="S"
            clearable={true}
            containerStyle={ADD_TO_ANAMNESIS_REPORT_CONTAINER_STYLE}
            value={element.atn.anamnesisReportLabel}
            onChange={this.onChangeAnamnesisReportLabel}
            placeholder={`Anzeigetext Anamnesereport`}
            inputType="text"
          />
        )}
        <TextareaInputIndication>
          <Input
            clearable={true}
            value={element.atn.placeholder[activeLang]}
            onChange={this.onChangePlaceholder}
            placeholder={placeholder(
              this.props,
              "placeholder",
              "Platzhalter im Textfeld"
            )}
            inputType="text"
            append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
          />
        </TextareaInputIndication>
        <RequiredInput
          activeLang={activeLang}
          multiLanguage={multiLanguage}
          onChange={onChange}
          required={element.atn.required}
          errorMessage={element.atn.errorMessage}
        />
        <LabeledInlineElement label="Erlaube Kamera-Input">
          <StyledToggleButton
            value={element.atn.allowCameraInput}
            onChange={this.onChangeAllowCameraInput}
          />
        </LabeledInlineElement>
      </Root>
    );
  }
}

export default TextareaInput;
