import React, { Component } from "react";
import styled from "@emotion/styled";

const Root = styled("div")`
  height: 15px;
  border-bottom: 1px solid #333;
  width: 100%;
`;

class Separator extends Component {
  render() {
    return <Root />;
  }
}

export default Separator;
