// @flow

import colors from "./colors";
import recallElementAtn from "./helpers/recallElement";

export const STD_ERROR_MESSAGE_INPUT = {
  de: "Bitte füllen Sie aus",
  en: "Please fill in",
  fr: "Remplir S'il vous plaît",
  es: "Llenalo por favor",
  tr: "Lütfen doldurun",
  pl: "Proszę wypełnić",
  ru: "Пожалуйста, заполните",
  ch: "请填写",
  ja: "埋めてください",
  ar: "يرجى ملء",
};

export const STD_ERROR_MESSAGE_OPTION = {
  de: "Bitte wählen Sie aus",
  en: "Please select",
  fr: "Veuillez choisir",
  es: "Seleccione por favor",
  tr: "Lütfen seçin",
  pl: "Proszę wybrać",
  ru: "Пожалуйста выберите",
  ch: "请选择",
  ja: "選んでください",
  ar: "يرجى الاختيار",
};

export const STD_ERROR_MESSAGE_SIGNATURE = {
  de: "Bitte unterschreiben Sie",
  en: "Please sign",
  fr: "Signez s'il-vous-plaît",
  es: "Firma por favor",
  tr: "Lütfen imzalayın",
  pl: "Proszę podpisz",
  ru: "Пожалуйста подпишите",
  ch: "请签字",
  ja: "署名してください",
  ar: "الرجاء تسجيل الدخول",
};

export const STD_ERROR_MESSAGE_CAMERA_INPUT = {
  de: "Bitte nehmen Sie ein Foto auf",
  en: "Please take a photo",
  fr: "Prendre une photo s'il-vous-plaît",
  es: "Por favor toma una foto",
  tr: "Lütfen fotoğrafını çek",
  pl: "Zrób zdjęcie",
  ru: "Пожалуйста, сделайте фото",
  ch: "请拍张照片",
  ja: "写真を撮ってください",
  ar: "يرجى التقاط صورة",
};

export const SIGNATURE_TEXT = {
  de: "Hier klicken zum Unterschreiben.",
  en: "Click here to sign.",
  fr: "Cliquez ici pour signer.",
  es: "Haga clic aquí para firmar.",
  tr: "İmzalamak için buraya tıklayın.",
  pl: "Kliknij tutaj, aby podpisać.",
  ru: "Нажмите здесь, чтобы подписать.",
  ch: "点击这里签名.",
  ja: "署名するにはここをクリック.",
  ar: "انقر هنا للتوقيع",
};

export const ICON_FOR_TYPE = {
  group: "columns",
  separator: "minus",
  spacer: "arrows-alt-v",
  heading: "heading",
  text: "font",
  paragraph: "font",
  list: "list-ul",
  image: "image",
  video: "video",
  textInput: "keyboard",
  textAreaInput: "keyboard",
  yesNo: "check-square",
  selectOne: "check",
  selectMultiple: "check-double",
  cameraInput: "camera",
  signature: "signature",
  recall: "bell",
};

export const NAME_FOR_TYPE = {
  group: "Spalten",
  separator: "Trennlinie",
  spacer: "Platzhalter",
  heading: "Überschrift",
  text: "Kurzer Text",
  paragraph: "Langer Text",
  list: "Liste",
  listItem: "List Item",
  image: "Bild",
  video: "Video",
  textInput: "Kleines Textfeld",
  textAreaInput: "Großes Textfeld",
  yesNo: "Ja / Nein",
  selectOne: "Auswählfeld",
  checkbox: "Checkbox",
  selectMultiple: "Ankreuzfeld",
  radioButton: "Auswahl",
  cameraInput: "Aufnahme",
  signature: "Unterschrift",
  recall: "Recall",
};

// SUPER COLORFUL SETUP
export const COLOR_FOR_TYPE = {
  group: colors[14],
  separator: colors[14],
  spacer: colors[14],
  heading: colors[1],
  text: colors[2],
  paragraph: colors[3],
  list: colors[4],
  listItem: colors[7],
  image: colors[5],
  video: colors[6],
  textInput: colors[13],
  textAreaInput: colors[12],
  yesNo: colors[7],
  selectOne: colors[7],
  checkbox: colors[7],
  selectMultiple: colors[11],
  radioButton: colors[11],
  cameraInput: colors[8],
  signature: colors[10],
  recall: colors[12],
};

export const TYPE_FOR_STATIC_ROOT_ID = {
  [recallElementAtn.id]: "recall",
};

// SUPER BORING SETUP
// export const COLOR_FOR_TYPE = {
//   group: "#fff",
//   separator: "#fff",
//   heading: "#fff",
//   text: "#fff",
//   paragraph: "#fff",
//   list: "#fff",
//   listItem: "#fff",
//   image: "#fff",
//   video: "#fff",
//   textInput: "#fff",
//   textAreaInput: "#fff",
//   selectOne: "#fff",
//   checkbox: "#fff",
//   selectMultiple: "#fff",
//   radioButton: "#fff",
//   signature: "#fff"
// };

// ATN ELEMENTS

export const GROUP = {
  name: NAME_FOR_TYPE.group,
  color: COLOR_FOR_TYPE.group,
  defaultAtn: {
    type: "group",
    children: [],
  },
};
export const SEPARATOR = {
  name: NAME_FOR_TYPE.separator,
  color: COLOR_FOR_TYPE.separator,
  defaultAtn: {
    type: "separator",
    style: {
      line: "wide",
    },
  },
};
export const SPACER = {
  name: NAME_FOR_TYPE.spacer,
  color: COLOR_FOR_TYPE.spacer,
  defaultAtn: {
    type: "spacer",
    style: {
      size: "m",
    },
  },
};
export const HEADLINE = {
  name: NAME_FOR_TYPE.heading,
  color: COLOR_FOR_TYPE.heading,
  defaultAtn: {
    type: "heading",
    content: {},
    style: {
      headingSize: 2,
      align: "left",
    },
  },
};
export const TEXT = {
  name: NAME_FOR_TYPE.text,
  color: COLOR_FOR_TYPE.text,
  defaultAtn: {
    type: "text",
    content: {},
  },
};
export const PARAGRAPH = {
  name: NAME_FOR_TYPE.paragraph,
  color: COLOR_FOR_TYPE.paragraph,
  defaultAtn: {
    type: "paragraph",
    content: {},
    style: {
      align: "left",
    },
  },
};
export const LIST = {
  name: NAME_FOR_TYPE.list,
  color: COLOR_FOR_TYPE.list,
  defaultAtn: {
    type: "list",
    items: [],
    style: {
      order: "unordered",
    },
  },
};
export const LIST_ITEM = {
  name: NAME_FOR_TYPE.listItem,
  color: COLOR_FOR_TYPE.listItem,
  defaultAtn: {
    type: "listItem",
    content: {},
  },
};
export const IMAGE = {
  name: NAME_FOR_TYPE.image,
  color: COLOR_FOR_TYPE.image,
  defaultAtn: {
    type: "image",
    source: "",
    sizes: {},
    style: {
      align: "center",
      size: "original",
    },
  },
};
export const VIDEO = {
  name: NAME_FOR_TYPE.video,
  color: COLOR_FOR_TYPE.video,
  defaultAtn: {
    type: "video",
    source: "",
    placeholderSource: "",
    style: {
      align: "center",
      size: "m",
    },
  },
};
export const TEXT_INPUT = {
  name: NAME_FOR_TYPE.textInput,
  color: COLOR_FOR_TYPE.textInput,
  defaultAtn: {
    type: "textInput",
    required: true,
    label: {},
    placeholder: {},
    errorMessage: STD_ERROR_MESSAGE_INPUT,
    style: {
      position: "left",
      size: "m",
    },
  },
};
export const TEXT_AREA_INPUT = {
  name: NAME_FOR_TYPE.textAreaInput,
  color: COLOR_FOR_TYPE.textAreaInput,
  defaultAtn: {
    type: "textAreaInput",
    required: true,
    label: {},
    placeholder: {},
    errorMessage: STD_ERROR_MESSAGE_INPUT,
    style: {
      position: "top",
      size: "m",
    },
  },
};
export const SELECT_ONE = {
  name: NAME_FOR_TYPE.selectOne,
  color: COLOR_FOR_TYPE.selectOne,
  defaultAtn: {
    type: "selectOne",
    required: true,
    label: {},
    errorMessage: STD_ERROR_MESSAGE_OPTION,
    options: [],
    style: {
      position: "left",
      optionsArrangement: "vertical",
    },
  },
};
export const YES_NO = {
  name: NAME_FOR_TYPE.yesNo,
  color: COLOR_FOR_TYPE.yesNo,
  defaultAtn: {
    type: "yesNo",
    required: true,
    label: {},
    errorMessage: STD_ERROR_MESSAGE_OPTION,
    options: [],
    style: {
      position: "left",
      optionsArrangement: "horizontal",
    },
  },
};
export const RADIO_BUTTON = {
  name: NAME_FOR_TYPE.radioButton,
  color: COLOR_FOR_TYPE.radioButton,
  defaultAtn: {
    type: "radioButton",
    label: {},
    children: [],
  },
};
export const SELECT_MULTIPLE = {
  name: NAME_FOR_TYPE.selectMultiple,
  color: COLOR_FOR_TYPE.selectMultiple,
  defaultAtn: {
    type: "selectMultiple",
    required: true,
    label: {},
    errorMessage: STD_ERROR_MESSAGE_OPTION,
    options: [],
    style: {
      position: "left",
      optionsArrangement: "vertical",
    },
  },
};
export const CHECKBOX = {
  name: NAME_FOR_TYPE.checkbox,
  color: COLOR_FOR_TYPE.checkbox,
  defaultAtn: {
    type: "checkbox",
    label: {},
    children: [],
  },
};
export const CAMERA_INPUT = {
  name: NAME_FOR_TYPE.cameraInput,
  color: COLOR_FOR_TYPE.cameraInput,
  defaultAtn: {
    type: "cameraInput",
    label: {},
    required: false,
    errorMessage: STD_ERROR_MESSAGE_CAMERA_INPUT,
  },
};
export const SIGNATURE = {
  name: NAME_FOR_TYPE.signature,
  color: COLOR_FOR_TYPE.signature,
  defaultAtn: {
    type: "signature",
    required: true,
    infoLabel: SIGNATURE_TEXT,
    errorMessage: STD_ERROR_MESSAGE_SIGNATURE,
    caption: {},
    style: {
      align: "left",
    },
  },
};
export const RECALL = {
  name: NAME_FOR_TYPE.recall,
  color: COLOR_FOR_TYPE.recall,
  atn: [recallElementAtn],
  static: true,
  staticType: "recall",
};
