import React from "react";
import styled from "@emotion/styled";

import SizePicker from "../style-form/SizePicker";

const Root = styled("div")`
  margin: 0;
`;

const Spacer = props => {
  return (
    <Root>
      <SizePicker
        label="Vertikale Größe"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
    </Root>
  );
};

export default Spacer;
