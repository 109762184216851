// @flow

import React from "react";

import RadioButton from "../form/RadioButton";
import StylePicker from "./StylePicker";

type PropsType = {
  label: string,
  value: { size: string },
  onChange: (string, string) => void
};

const ArrangementPicker = (props: PropsType) => {
  return (
    <StylePicker label={props.label}>
      <RadioButton
        value={props.value.optionsArrangement}
        options={[
          { label: "Horizontal", value: "horizontal" },
          { label: "Vertikal", value: "vertical" }
        ]}
        onChange={value => props.onChange("optionsArrangement", value)}
      />
    </StylePicker>
  );
};

export default ArrangementPicker;
