import React, { Component } from "react";
import styled from "@emotion/styled";

// https://stackoverflow.com/questions/18012420/draw-diagonal-lines-in-div-background-with-css
const Root = styled("div")`
  height: 80px;
  width: 100%;
  border: 1px solid #ccc;
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
`;

class Spacer extends Component {
  render() {
    return <Root />;
  }
}

export default Spacer;
