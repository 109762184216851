// @flow

import React from "react";
import Hotkeys from "react-hot-keys";
import { connect } from "react-redux";

import { toggleFeature } from "../../actions";

export default connect(
  () => ({}),
  dispatch => ({
    onToggle: feature => dispatch(toggleFeature(feature))
  })
)(({ multiLanguage, onToggle }) => (
  <>
    <Hotkeys keyName="alt+m" onKeyUp={() => onToggle("multiLanguage")} />
    <Hotkeys keyName="alt+d" onKeyUp={() => onToggle("download")} />
  </>
));
