import styled from "@emotion/styled";

const StyleEditorHeader = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 20px;
  border-bottom: 1px solid #ccc;
`;

export default StyleEditorHeader;
