// @flow

import React from "react";
import styled from "@emotion/styled";

import { redirectToDocumentTemplate,RETURN_URL } from "../../api";

import TextButton from "./TextButton";
import FaIcon from "../main/FaIcon";

const MyTextButton = styled(TextButton)`
  svg {
    color: white;
  }
`;

const BackButton = ({ backPath, className }) => (
  <MyTextButton
    size="L"
    noBorder={true}
    className={className}
    onClick={() => {
      window.location.href = RETURN_URL + "/external/document_templates";
    }}
  >
    <FaIcon left size="L" icon="chevron-left" /> Zurück
  </MyTextButton>
);

export default BackButton;
