// @flow

import type { ElementType } from "../reducers/elementsReducer";
import type { ATNValueType } from "../types";

import { TRANSLATABLE_ATN_KEYS, AVAILABLE_LANGUAGES } from "../atnConfig";
import {
  TYPE_FOR_STATIC_ROOT_ID,
  COLOR_FOR_TYPE,
  NAME_FOR_TYPE,
} from "../atnBase";

import { uuidv4 } from "./index";

function getInitialLanguageMap() {
  return AVAILABLE_LANGUAGES.reduce((obj, lang) => {
    obj[lang] = "";

    return obj;
  }, {});
}

function initTranslatableValues(atnValue, languages) {
  return Object.keys(atnValue).reduce((obj, key) => {
    obj[key] = atnValue[key];

    // Error messages are initialized with default strings already (see atnBase.js)
    if (key !== "errorMessage" && TRANSLATABLE_ATN_KEYS.includes(key)) {
      obj[key] = languages;
    }

    return obj;
  }, {});
}

function initAtn(atn: ATNValueType) {
  const languages = getInitialLanguageMap();

  return initTranslatableValues(atn, languages);
}

export function initializeElement(element: ElementType) {
  return {
    ...element,
    id: uuidv4(),
    atn: JSON.parse(JSON.stringify(initAtn(element.defaultAtn))), // Deep copy
  };
}

export function placeholder(props, type, defaultPlaceholder) {
  const { element, settings } = props;

  const toTranslate = element.atn[type][settings.languages[0]];

  return toTranslate ? toTranslate : defaultPlaceholder;
}

const isStaticRootId = (id) => !!TYPE_FOR_STATIC_ROOT_ID[id];

export const isStaticRootElement = (props) => isStaticRootId(props.elementId);

export const isStaticElement = (props) => {
  if (!props.element.atn) return false;
  return !!props.element.atn.static;
};

export const isInitializedElement = (element) => !!element.id;

export const colorOfStaticElement = (staticId) =>
  COLOR_FOR_TYPE[TYPE_FOR_STATIC_ROOT_ID[staticId]];

export const nameOfStaticELement = (staticId) =>
  NAME_FOR_TYPE[TYPE_FOR_STATIC_ROOT_ID[staticId]];
