import React from "react";
import styled from "@emotion/styled";
import { DropTarget } from "react-dnd";

const Root = styled("div")`
  width: 100%;
  height: 15px;

  border-top: ${({ isOver, below }) =>
    isOver ? (below ? "none" : "6px solid #015270") : "none"};
  border-bottom: ${({ isOver, below }) =>
    isOver ? (below ? "6px solid #015270" : "none") : "none"};

  transform: ${({ below }) => (below ? "translateY(3px)" : "translateY(-3px)")};
  margin: ${({ below }) => (below ? "-3px 0 0" : "0 0 -3px")};
`;

const target = {
  canDrop() {
    return true;
  },
  hover() {},
  drop(props, monitor, component) {
    const id = monitor.getItem().id;
    const element = monitor.getItem().element;

    props.onDrop(id, element);
  }
};

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

const Indicator = ({ connectDropTarget, className, isOver, below }) => {
  return (
    <Root
      isOver={isOver}
      below={below}
      ref={instance => connectDropTarget(instance)}
      className={className}
    />
  );
};

export default DropTarget("EDITOR_ELEMENT", target, collect)(Indicator);
