// @flow

import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { batchDispatchMiddleware } from "redux-batched-actions";

import reducers from "./reducers";

const middlewares = [thunk, batchDispatchMiddleware];

function makeStore(initialState: Object, middleware) {
  let enhancer;

  if (process.env.NODE_ENV !== "production")
    enhancer = compose(
      middleware,
      // Use the Redux devtools extention, but only in development
      window && window.devToolsExtension ? window.devToolsExtension() : f => f
    );
  else enhancer = compose(middleware);

  return createStore(reducers, initialState, enhancer);
}

const middleware = applyMiddleware(...middlewares);

export default () => makeStore({}, middleware);
