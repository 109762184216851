import React, { Component } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { DropTarget } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import accept from "attr-accept";

import { uploadFile } from "../../api";

const Dropzone = styled("div")`
  ${({ hasItem, isDragAccept }) => {
    if (hasItem && isDragAccept)
      return css`
        border: 4px solid #015270;
        padding: 10px;
      `;

    if (hasItem)
      return css`
        border: 4px dashed #015270;
        padding: 10px;
      `;
  }};
`;

const HiddenInputField = styled("input")`
  display: none;
`;

const collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  hasItem: !!monitor.getItem() && monitor.getItem().hasOwnProperty("files")
});

const target = {
  drop(props, monitor) {
    const file = monitor.getItem().files[0];

    if (accept(file, "image/*")) {
      props.onDrop(file);
    }
  }
};

class DropArea extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  open = () => {
    this.inputRef.current.click();
  };

  render() {
    const { connectDropTarget, isOver, onDrop, hasItem } = this.props;

    return (
      <Dropzone
        ref={instance => connectDropTarget(instance)}
        isDragAccept={isOver}
        hasItem={hasItem}
      >
        {this.props.children({
          openFileSelect: this.open
        })}
        <HiddenInputField
          ref={this.inputRef}
          type="file"
          accept="image/*"
          onChange={e => onDrop(e.target.files[0])}
        />
      </Dropzone>
    );
  }
}

const ConnectedDropArea = DropTarget(NativeTypes.FILE, target, collect)(
  DropArea
);

/*
  UploadArea wraps the connected DropArea,
  because both, the DropTarget and the DropArea, need "onDrop" as a prop
*/
class UploadArea extends Component {
  onDrop = file => {
    this.props.onChangeFile(file);
    this.props.onUploadStart();

    return uploadFile(file)
      .then(result =>{
debugger
        this.props.onUploadSuccess(result)
        })
      .catch(e => {
        console.error(e);
        this.props.onUploadError();
      });
  };

  render() {
    return (
      <ConnectedDropArea onDrop={this.onDrop}>
        {this.props.children}
      </ConnectedDropArea>
    );
  }
}

export default UploadArea;
