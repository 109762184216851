// @flow

export type StateType = {
  docSave: {
    loading: boolean,
    error: boolean,
    success: boolean
  },
  docLoad: {
    loading: boolean,
    error: boolean,
    success: boolean
  },
  dirty: boolean
};

export const initialState: StateType = {
  docSave: {
    loading: false,
    error: false,
    success: false
  },
  docLoad: {
    loading: false,
    error: false,
    success: false
  }
};

/*
  Looks long, but is super simple
  - docSave and docLoad work the same way, help to display errors + loading spinner
  - docFullyLoaded helps prevent certain behavior until the doc is fully loaded
    (right now only: scrolling to new elements)
*/
function reducer(state: StateType = initialState, action: Object): StateType {
  switch (action.type) {
    case "DOC_SAVE_START":
      return {
        ...state,
        docSave: {
          ...state.docSave,
          loading: true,
          success: false,
          error: false
        }
      };
    case "DOC_SAVE_SUCCESS":
      return {
        ...state,
        docSave: {
          ...state.docSave,
          loading: false,
          success: true,
          error: false
        },
        dirty: false
      };
    case "DOC_SAVE_ERROR":
      return {
        ...state,
        docSave: {
          ...state.docSave,
          loading: false,
          success: false,
          error: true
        }
      };
    case "DOC_LOAD_START":
      return {
        ...state,
        docLoad: {
          ...state.docLoad,
          loading: true,
          success: false,
          error: false
        }
      };
    case "DOC_LOAD_SUCCESS":
      return {
        ...state,
        docLoad: {
          ...state.docLoad,
          loading: false,
          success: true,
          error: false
        },
        dirty: false
      };
    case "DOC_LOAD_ERROR":
      return {
        ...state,
        docLoad: {
          ...state.docLoad,
          loading: false,
          success: false,
          error: true
        }
      };
    case "ELEMENT_SET_ATTR":
      return { ...state, dirty: true };
    case "ELEMENT_SET_STYLE_ATTR":
      return { ...state, dirty: true };
    case "ELEMENTS_REPLACE":
      return { ...state, dirty: true };
    case "ELEMENT_ADD":
      return { ...state, dirty: true };
    case "ELEMENT_ADD_AND_MOVE":
      return { ...state, dirty: true };
    case "ELEMENT_ADD_AND_REGISTER_CHILD":
      return { ...state, dirty: true };
    case "ELEMENT_REMOVE":
      return { ...state, dirty: true };
    case "ELEMENT_COPY":
      return { ...state, dirty: true };
    case "ELEMENT_MOVE":
      return { ...state, dirty: true };
    case "ELEMENT_ADD_CHILD":
      return { ...state, dirty: true };
    case "ELEMENT_REGISTER_CHILD":
      return { ...state, dirty: true };
    default:
      return state;
  }
}

export default reducer;
