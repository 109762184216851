import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import styled from "@emotion/styled";

const Root = styled("div")`
  width: 100%;
  position: relative;

  .mce-tinymce {
    width: initial !important;
    display: inline-block;
    box-shadow: none !important;
  }

  .mce-edit-focus {
    outline: none;
  }
`;

const InputField = styled("div")`
  padding: 8px 30px 8px 8px;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  outline-width: 0;

  p {
    margin: 0;
  }
`;

const FixedToolbar = styled("div")`
  position: absolute;
  top: -35px;
  left: 0;
`;

const Append = styled("span")`
  position: absolute;
  top: 1px;
  right: 30px;
  font-size: 15px;
`;

class RichText extends React.Component {
  handleEditorChange = e => {
    this.props.onChange(e.target.getContent());
  };

  render() {
    // Fun note: TinyMCE's onChange fires onBlur

    return (
      <Root>
        <Editor
        apiKey="19noyl1hgonnf47liszarq7vi7ms6e77qiasf2sswc8mgdkk"
          initialValue={this.props.value}
          inline={true}
          placeholder="Yo mama"
          toolbar="bold italic underline superscript forecolor"
          init={{
            ...this.props.init,
            plugins: "textcolor, paste",
            selector: "#" + this.props.theId,
            fixed_toolbar_container: "#" + this.props.theId + "toolbar",
            browser_spellcheck: true,
            contextmenu: false,
            menubar: false,
            statusbar: false,
            entity_encoding: "raw",
            paste_as_text: true
          }}
          onChange={this.handleEditorChange}
        />
        <FixedToolbar id={this.props.theId + "toolbar"} />
        <InputField id={this.props.theId} placeholder="Hallo Welt" />
        {!!this.props.append && <Append>{this.props.append}</Append>}
      </Root>
    );
  }
}

export default RichText;
