// @flow

import * as React from "react";
import styled from "@emotion/styled";

import arrowIndication from "../../img/arrowIndication.svg";

const Root = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 24px;
  padding: 150px 20px;
  color: #888;
  max-width: 400px;
`;

const Arrow = styled("img")`
  height: 100px;
  margin-left: -50px;
  margin-right: 10px;
`;

type PropsType = {
  children: React.Node,
  showArrow?: boolean
};

const AreaEmpty = ({ children, showArrow, ...props }: PropsType) => {
  return (
    <Root {...props}>
      {showArrow && <Arrow src={arrowIndication} />}
      <p>{children}</p>
    </Root>
  );
};

export default AreaEmpty;
