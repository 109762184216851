// @flow

import React from "react";
import styled from "@emotion/styled";

import LabeledInlineElement from "./LabeledInlineElement";
import ToggleButton from "./ToggleButton";
import Input from "./Input";
import ActiveLang from "../styled/ActiveLang";

const StyledToggleButton = styled(ToggleButton)`
  margin: 0 10px 0 0;
`;

type PropsType = {
  className?: string,
  activeLang: string,
  multiLanguage: boolean,
  required: boolean,
  errorMessage: Object,
  onChange: (string, any) => void
};

const INPUT_PROPS = { full: true, rows: 2 };

class RequiredInput extends React.Component<PropsType> {
  onChangeErrorMessage = (value: string) => {
    this.props.onChange("errorMessage", {
      ...this.props.errorMessage,
      [this.props.activeLang]: value
    });
  };
  onChangeRequired = (value: boolean) => {
    this.props.onChange("required", value);
  };

  render() {
    const {
      className,
      activeLang,
      multiLanguage,
      required,
      errorMessage
    } = this.props;

    return (
      <LabeledInlineElement className={className} label="Erforderlich">
        <StyledToggleButton value={required} onChange={this.onChangeRequired} />
        {required && (
          <Input
            key={activeLang}
            clearable={true}
            value={errorMessage[activeLang]}
            onChange={this.onChangeErrorMessage}
            inputProps={INPUT_PROPS}
            placeholder="Meldung falls leer"
            inputType="text"
            append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
          />
        )}
      </LabeledInlineElement>
    );
  }
}

export default RequiredInput;
