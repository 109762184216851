// @flow

import React, { Component } from "react";
import styled from "@emotion/styled";

import Input from "../form/Input";
import DsWinFieldNameSelect from "../form/DsWinFieldNameSelect";
import ToggleButton from "../form/ToggleButton";
import RequiredInput from "../form/RequiredInput";
import IconButton from "../form/IconButton";
import LabeledInlineElement from "../form/LabeledInlineElement";
import ActiveLang from "../styled/ActiveLang";
import AlternativeInput from "../styled/AlternativeInput";

import FaIcon from "../main/FaIcon";

import { translate } from "../../services/i18n";
import { placeholder } from "../../helpers/elementHelper";

const Root = styled("div")``;

const TextInputIndication = styled("div")`
  border: 2px dashed #ccc;
  padding: 5px;
  margin: 5px 0;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledToggleButton = styled(ToggleButton)`
  margin: 0 10px 0 0;
`;

const StyledInput = styled(Input)`
  ${AlternativeInput};
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 3px;
`;

const ADD_TO_ANAMNESIS_REPORT_CONTAINER_STYLE = { marginTop: "5px" };
const DS_WIN_SELECT_DEFAULT_VALUE = true;

class TextInput extends Component {
  onChangeAttr = (attr, value) => {
    this.props.onChange(attr, {
      ...this.props.element.atn[attr],
      [this.props.settings.activeLang]: value
    });
  };

  onChangePlaceholder = value => {
    this.onChangeAttr("placeholder", value);
  };

  onChangeLabel = value => {
    this.onChangeAttr("label", value);
  };

  onChangeAllowCameraInput = value => {
    this.props.onChange("allowCameraInput", value);
  };

  onChangeAnamnesisReportLabel = value => {
    this.props.onChange("anamnesisReportLabel", value);
  };

  onChangeDsWinFieldName = value => {
    if (!value) {
      delete this.props.element.atn.dsWinFieldName;
      return this.forceUpdate();
    }
    return this.props.onChange("dsWinFieldName", value);
  };

  onToggleAnamnesisReport = () => {
    this.props.onChange(
      "addToAnamnesisReport",
      !this.props.element.atn.addToAnamnesisReport
    );
    this.props.onChange("anamnesisReportLabel", null);
  };

  onToggleDsWinFieldName = () => {
    if (this.props.element.atn.dsWinFieldName) {
      return this.props.onRemoveElementAttribute("dsWinFieldName");
    }
    return this.onChangeDsWinFieldName(DS_WIN_SELECT_DEFAULT_VALUE);
  };

  render() {
    const {
      element,
      onChange,
      multiLanguage,
      settings: { activeLang, languages }
    } = this.props;

    return (
      <Root>
        <Row>
          <Input
            append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
            clearable={true}
            inline={true}
            inputType="text"
            key={activeLang}
            onChange={this.onChangeLabel}
            placeholder={placeholder(this.props, "label", "Bezeichnung")}
            value={element.atn.label[activeLang]}
          />
          <StyledIconButton
            tabIndex="-1"
            onClick={this.onToggleAnamnesisReport}
            title="Anamnesereport"
          >
            <FaIcon center light icon="file-alt" />
          </StyledIconButton>
          <StyledIconButton
            tabIndex="-1"
            title={translate("dsWinFieldNameSelect.patientDataChange")}
            active={element.atn.dsWinFieldName}
            onClick={this.onToggleDsWinFieldName}
          >
            <FaIcon center light icon="user" />
          </StyledIconButton>
        </Row>
        {element.atn.addToAnamnesisReport && (
          <StyledInput
            size="S"
            clearable={true}
            containerStyle={ADD_TO_ANAMNESIS_REPORT_CONTAINER_STYLE}
            value={element.atn.anamnesisReportLabel || ""}
            onChange={this.onChangeAnamnesisReportLabel}
            placeholder={`Anzeigetext Anamnesereport`}
            inputType="text"
          />
        )}
        {element.atn.dsWinFieldName && (
          <DsWinFieldNameSelect
            size="S"
            containerStyle={ADD_TO_ANAMNESIS_REPORT_CONTAINER_STYLE}
            value={element.atn.dsWinFieldName || ""}
            onChange={this.onChangeDsWinFieldName}
          />
        )}
        <TextInputIndication>
          <Input
            append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
            clearable={true}
            inputType="text"
            key={activeLang}
            onChange={this.onChangePlaceholder}
            placeholder={placeholder(
              this.props,
              "placeholder",
              "Platzhalter im Textfeld"
            )}
            value={element.atn.placeholder[activeLang]}
          />
        </TextInputIndication>
        <RequiredInput
          activeLang={activeLang}
          multiLanguage={multiLanguage}
          onChange={onChange}
          required={element.atn.required}
          errorMessage={element.atn.errorMessage}
        />
        <LabeledInlineElement label="Erlaube Kamera-Input">
          <StyledToggleButton
            value={element.atn.allowCameraInput}
            onChange={this.onChangeAllowCameraInput}
          />
        </LabeledInlineElement>
      </Root>
    );
  }
}

export default TextInput;
