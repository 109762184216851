// @flow

import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import Preview from "./main/Preview";
import FeatureToggler from "./main/FeatureToggler";
import Header from "./main/Header";
import Drawer from "./main/Drawer";
import StyleEditor from "./main/StyleEditor";
import Editor from "./main/Editor";
import DragDropMain from "./main/DragDropMain";
import DocumentHeader from "./main/DocumentHeader";
import Document from "./main/Document";
import DocumentBody from "./main/DocumentBody";
import SnackMessage from "./main/SnackMessage";
import VariablePicker from "./main/VariablePicker";
import ErrorPage from "./main/ErrorPage";
import OldBrowserNotice from "./main/OldBrowserNotice";

const Root = styled("div")`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledDrawer = styled(Drawer)`
  width: 235px;
  flex-shrink: 0;
  border-right: 1px solid #ccc;
`;

const StyledDragDropMain = styled(DragDropMain)`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

const StyledStyleEditor = styled(StyleEditor)`
  width: 300px;
  overflow-y: auto;
  border-left: 1px solid #ccc;
  flex-shrink: 0;
  flex-grow: 1;
`;

const StyledEditor = styled(Editor)`
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledVariablePicker = styled(VariablePicker)`
  width: 300px;
  border-left: 1px solid #ccc;
  flex-shrink: 0;
`;

const RightCol = styled("div")`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

type PropsType = {
  backPath: string,
  readOnly: boolean
};

type StateType = {
  error: boolean
};

class App extends PureComponent<PropsType, StateType> {
  render() {
    return (
      <Root>
        <OldBrowserNotice />
        <Preview />
        <FeatureToggler />
        <Header backPath={this.props.backPath} />
        <SnackMessage />
        <StyledDragDropMain>
          <StyledDrawer />
          <Document>
            <DocumentHeader readOnly={this.props.readOnly} />
            <DocumentBody>
              <StyledEditor />
              <RightCol>
                <StyledStyleEditor />
                <StyledVariablePicker />
              </RightCol>
            </DocumentBody>
          </Document>
        </StyledDragDropMain>

      </Root>
    );
  }
}

export default App;
