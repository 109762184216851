// @flow

import React, { Component } from "react";
import styled from "@emotion/styled";

import { RADIO_BUTTON } from "../../atnBase";

import ElementWithNesting from "./ElementWithNesting";

const Bubble = styled("div")`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px dashed #ccc;
  margin: 0 8px 0 3px;
  flex-shrink: 0;
`;

class YesNo extends Component {
  render() {
    return (
      <ElementWithNesting
        {...this.props}
        nestingKey="options"
        nestingElement={RADIO_BUTTON}
        nestedContentKey="label"
        nestingIndicator={<Bubble />}
        allowsFurtherNesting={true}
        labelPlaceholder="Bsp: Nehmen Sie Medikamente?"
        optionPlaceholders={["Ja", "Nein"]}
        optionPlaceholder="Option"
        defaultValues={["Ja", "Nein"]}
      />
    );
  }
}

export default YesNo;
