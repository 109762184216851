import React from "react";
import styled from "@emotion/styled";

const Index = styled("div")`
  margin: 0;
  padding: 2px 10px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: inline-block;
  border-right: none;
  border-left: ${({ color }) => "3px solid " + color};
  text-align: center;
  line-height: 1.3;
  font-size: 16px;
`;

const Label = styled("div")`
  margin: 0;
  padding: 2px 15px;
  background-color: white;
  color: #333;
  border: 1px solid ${({ color }) => "#ccc"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 700;
  line-height: 1.3;
  font-size: 16px;
  flex-shrink: 0;
`;

export default ({ idx, label, color }) => (
  <>
    <Index color={color}>{idx}</Index>
    <Label color={color}>{label}</Label>
  </>
);
