// @flow

import * as React from "react";
import styled from "@emotion/styled";

const Root = styled("button")`
  padding: ${({ size }) => (size === "L" ? "10px 0px" : "5px 0px")};
  font-size: ${({ size }) => (size === "L" ? "18px" : "14px")};

  color: white;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: transparent;
  transition: border 0.1s, opacity 0.1s;
  line-height: 1;
  outline-width: 0;
  cursor: pointer;
  opacity: 1;

  &:hover {
    opacity: 0.9;
    border: ${({ noBorder }) =>
      noBorder ? "1px solid transparent" : "1px solid white"};
  }
`;

type PropsType = {
  children: React.Node,
  size?: string
};

const TextButton = ({ children, ...props }: PropsType) => {
  return (
    <Root type="button" {...props}>
      {children}
    </Root>
  );
};

export default TextButton;
