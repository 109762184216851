// @flow

import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";

import ComponentTitle from "../styled/ComponentTitle";
import GroupTitle from "../styled/GroupTitle";
import BasicButton from "../form/BasicButton";
import FaIcon from "../main/FaIcon";

const SUPPORTED_VARIABLES = [
  {
    title: "Allgemein",
    variables: [["Datum heute", "{{datum_heute}}"]],
  },
  {
    title: "Patient",
    variables: [
      ["Anrede", "{{patient_anrede}}"],
      ["Titel", "{{patient_titel}}"],
      ["Vorname", "{{patient_vorname}}"],
      ["Nachname", "{{patient_nachname}}"],
      ["Geburtsdatum", "{{patient_geburtsdatum}}"],
      ["Anschrift", "{{patient_anschrift}}"],
      ["Telefon privat", "{{patient_telefon_privat}}"],
      ["Telefon 2", "{{patient_telefon_2}}"],
      ["Telefon mobil", "{{patient_telefon_mobil}}"],
      ["Email", "{{patient_email}}"],
      ["Nummer", "{{patient_nummer}}"],
    ],
  },
  {
    title: "Praxis",
    variables: [
      ["Logo", "{{praxis_logo}}"],
      ["Name", "{{praxis_name}}"],
      ["Adresse", "{{praxis_adresse_1}}"],
      ["Postleitzahl", "{{praxis_postleitzahl}}"],
      ["Stadt", "{{praxis_stadt}}"],
      ["Telefon", "{{praxis_telefon}}"],
      ["Email", "{{praxis_email}}"],
      ["Factoring Unternehmen", "{{praxis_dsgvo_factoring_provider}}"],
      ["Datensch. Beauftragter", "{{praxis_datenschutzbeauftragter}}"],
      [
        "Datensch. Beauftragter Adresse",
        "{{praxis_datenschutzbeauftragter_adresse}}",
      ],
      [
        "Datensch. Beauftragter Kontakt",
        "{{praxis_datenschutzbeauftragter_kontaktdaten}}",
      ],
    ],
  },
  {
    title: "Versicherter",
    variables: [
      ["Anrede", "{{versicherter_anrede}}"],
      ["Titel", "{{versicherter_titel}}"],
      ["Vorname", "{{versicherter_vorname}}"],
      ["Nachname", "{{versicherter_nachname}}"],
      ["Geburtsdatum", "{{versicherter_geburtsdatum}}"],
      ["Anschrift", "{{versicherter_anschrift}}"],
      ["Land", "{{versicherter_land}}"],
      ["Telefon", "{{versicherter_telefon}}"],
    ],
  },
];

// ------------------------------
// Using DOM APIs to insert text into the input element at the cursor position.
//
// This might seem hacky / low-level, but the alternative would be
// to keep track of the input refs of all components in some top-level data structure
// to determine active element and cursor position, only to change the value top-down
// aka the "react way".
//
// So this approach is a little low-level: We change the input directly and then
// rely on natural DOM events, to update the redux store. You could say this is "bottom-up"
//
// Advantage: All logic for variable insertion can reside here in this single file.
// Disadvantage: It's low-level
// ------------------------------

function insertIntoTinyMCE(value) {
  if (window.tinymce && window.tinymce.activeEditor) {
    window.tinymce.activeEditor.execCommand("mceInsertContent", false, value);
  } else {
    console.warn("Couldn't find TinyMCE to insert variable");
  }
}

function insertIntoInput(input, value) {
  // Save cursor start and end position
  const start = input.selectionStart;
  const end = input.selectionEnd;

  // Hacky: update the value with our text inserted
  // Equvalent to // input.value = newValue;
  // which doesn't work because React overrides the input value setter
  // https://stackoverflow.com/a/46012210/921486
  const newValue = input.value.slice(0, start) + value + input.value.slice(end);
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value"
  ).set;
  nativeInputValueSetter.call(input, newValue);

  // Update cursor to be at the end of insertion
  input.selectionStart = input.selectionEnd = start + value.length;

  const event = new Event("change", { bubbles: true });

  input.dispatchEvent(event);
}

function onMouseDown(e, value) {
  /*
    We use mousedown instead of click, because onClick occurs after the
    focussed element has been blurred. With mousedown, we can prevent the blur:
  */
  e.preventDefault();

  // Get current text of the active input
  // TODO: this might not work in IE
  const input = document.activeElement;

  if (
    input instanceof HTMLInputElement ||
    input instanceof HTMLTextAreaElement
  ) {
    insertIntoInput(input, value);
  } else if (
    input instanceof HTMLDivElement &&
    input.className.indexOf("mce") !== -1
  ) {
    insertIntoTinyMCE(value);
  }

  // Else: Some other element is focussed, where we can't insert
}

const Root = styled("div")`
  background-color: #f3f3f3;
  border-top: 1px solid #ccc;
`;
const Padded = styled("div")`
  padding: 14px;
`;

const Variable = styled(BasicButton)`
  padding: 4px 7px;
  text-align: left;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin: 0 1px 2px;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
`;

const Variables = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const MainVariables = styled(Variables)`
  padding: 0 0 10px;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
const Col = styled("div")`
  padding-left: 10px;
  flex-grow: 1;
  &:first-of-type {
    padding-left: 0;
  }
`;
const TopCol = styled("div")`
  padding-top: 10px;
`;

const StyledComponentTitle = styled(ComponentTitle)`
  padding: 14px 20px;
`;

function isInput(domElement) {
  return (
    domElement instanceof HTMLInputElement ||
    domElement instanceof HTMLTextAreaElement ||
    (domElement instanceof HTMLDivElement &&
      domElement.className.indexOf("mce") !== -1)
  );
}

export default ({ className }) => {
  const [shouldRender, setShouldRender] = useState(
    isInput(document.activeElement)
  );

  useEffect(() => {
    let lastActiveElement = document.activeElement;

    function isSameActiveElement(lastEl, newEl) {
      if (lastEl !== newEl) {
        lastEl = newEl;
        return false;
      }

      return true;
    }

    function detectBlurOrFocus() {
      if (!isSameActiveElement(lastActiveElement, document.activeElement)) {
        setShouldRender(isInput(document.activeElement));
      }
    }

    window.addEventListener("focus", detectBlurOrFocus, true);
    window.addEventListener("blur", detectBlurOrFocus, true);

    return function cleanup() {
      window.removeEventListener("focus", detectBlurOrFocus, true);
      window.removeEventListener("blur", detectBlurOrFocus, true);
    };
  });

  if (!shouldRender) return null;

  return (
    <Root className={className}>
      <StyledComponentTitle>
        <FaIcon left size="L" icon="code" />
        Platzhalter im Text
      </StyledComponentTitle>
      <Padded>
        <TopCol>
          <GroupTitle>{SUPPORTED_VARIABLES[0].title}</GroupTitle>
          <MainVariables>
            {SUPPORTED_VARIABLES[0].variables.map(([name, value]) => (
              <Variable key={value} onMouseDown={(e) => onMouseDown(e, value)}>
                {name}
              </Variable>
            ))}
          </MainVariables>
          <GroupTitle>{SUPPORTED_VARIABLES[2].title}</GroupTitle>
          <MainVariables>
            {SUPPORTED_VARIABLES[2].variables.map(([name, value]) => (
              <Variable key={value} onMouseDown={(e) => onMouseDown(e, value)}>
                {name}
              </Variable>
            ))}
          </MainVariables>
        </TopCol>
        <Row>
          <Col>
            <GroupTitle>{SUPPORTED_VARIABLES[1].title}</GroupTitle>
            <Variables>
              {SUPPORTED_VARIABLES[1].variables.map(([name, value]) => (
                <Variable
                  key={value}
                  onMouseDown={(e) => onMouseDown(e, value)}
                >
                  {name}
                </Variable>
              ))}
            </Variables>
          </Col>
          <Col>
            <GroupTitle>{SUPPORTED_VARIABLES[3].title}</GroupTitle>
            <Variables>
              {SUPPORTED_VARIABLES[3].variables.map(([name, value]) => (
                <Variable
                  key={value}
                  onMouseDown={(e) => onMouseDown(e, value)}
                >
                  {name}
                </Variable>
              ))}
            </Variables>
          </Col>
        </Row>
      </Padded>
    </Root>
  );
};
