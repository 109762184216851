import React, { Component } from "react";
import styled from "@emotion/styled";

const Root = styled("div")`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const Content = styled("div")`
  color: #333;
  max-width: 500px;
`;
const Heading = styled("h2")``;
const Description = styled("p")`
  font-size: 18px;
`;

class ErrorPage extends Component {
  render() {
    return (
      <Root>
        <Content>
          <Heading>Fehler im Editor</Heading>
          <Description>Wir werden uns das Problem ansehen.</Description>
          <Description>
            Bitte <strong>kopieren Sie die URL</strong> und senden Sie sie an{" "}
            <a href="mailto:athena@dampsoft.de">athena@dampsoft.de</a>.
          </Description>
        </Content>
      </Root>
    );
  }
}

export default ErrorPage;
