import React, { Component } from "react";
import styled from "@emotion/styled";

import { getSignedUrl } from "../../api";

import Input from "../form/Input";
import IconButton from "../form/IconButton";
import FaIcon from "../main/FaIcon";
import UploadableArea from "../form/UploadableArea";
import BasicButton from "../form/BasicButton";

const Or = styled("span")`
  padding-left: 7px;
  font-size: 14px;
`;

const Row = styled("div")`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Preview = styled("img")`
  display: block;
  height: 100px;
  width: auto;
`;

class Image extends Component {
  state = {
    loading: false,
    previewSrc: null
  };

  componentDidMount() {
    debugger
    if (this.props.element.atn.source) {
      this.setState({ previewSrc: this.props.element.atn.source });
    }
  }

  setPreview = file => {
    this.setState({ previewSrc: file ? URL.createObjectURL(file) : null });
  };

  setLoading = value => {
    this.setState({ loading: value });
  };

  onChange = value => {
    debugger
    this.props.onChange("source", value);
  };

  reset = () => {
    this.onChange("");
    this.props.onChange("sizes", {});
    this.setPreview(null);
  };

  onUploadStart = () => {
    this.setLoading(true);
  };

  getPreviewAndChangeValue = id => {
    getSignedUrl(id,this)
      .then(({ data: {original, small, medium, large } }) => {
        debugger
        // this.onChange(original);
        this.props.onChange("sizes", {
          small,
          medium,
          large
        });
        this.setLoading(false);
      })
      .catch(e => {
        console.error(e);
        this.reset();
      });
  };

  render() {
    const { element } = this.props;
    const { loading, previewSrc } = this.state;

    const showPreview = !!previewSrc && previewSrc.indexOf("http") !== -1;

    return (
      <UploadableArea
        onChangeFile={this.setPreview}
        onUploadStart={this.setLoading}
        onUploadSuccess={this.getPreviewAndChangeValue}
        onUploadError={this.reset}
      >
        {({ openFileSelect }) => {
          return (
            <>
              {showPreview && (
                <>
                  <Preview src={this.state.previewSrc} />
                  <IconButton disabled={loading} onClick={this.reset}>
                    {loading ? (
                      <span>lädt hoch ...</span>
                    ) : (
                      <>
                        <FaIcon left light icon="times" />
                        Entfernen
                      </>
                    )}
                  </IconButton>
                </>
              )}
              {!showPreview && (
                <>
                  <Input
                    clearable={true}
                    value={element.atn.source}
                    onChange={this.onChange}
                    placeholder="https://link.to/image.png"
                    inputType="text"
                  />
                  <Row>
                    <BasicButton onClick={openFileSelect}>
                      Bild auswählen
                    </BasicButton>
                    <Or>oder Bild per Drag & Drop hinzufügen.</Or>
                  </Row>
                </>
              )}
            </>
          );
        }}
      </UploadableArea>
    );
  }
}

export default Image;
