// @flow

import * as React from "react";
import styled from "@emotion/styled";

import PreviewElement from "./PreviewElement";

const Root = styled("div")`
  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: #f3f3f3;
`;

const Frame = styled("div")`
  max-width: 1000px;
  margin: 10px auto 0;
  padding: 20px 40px;
`;

const WhiteFrame = styled(Frame)`
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
  margin: 10px auto 40px;
`;

const ShortNote = styled("p")`
  font-size: 14px;
  margin: 0px;
`;

const DocumentTitle = styled("h1")`
  margin: 0;
`;

type PropsType = {
  activeLang: string,
  atn: Object,
  header?: React.Node,
  title: string
};

const PreviewContent = ({ title, atn, header, activeLang }: PropsType) => {
  return (
    <Root>
      {header}
      <Frame>
        <ShortNote>Vorschau für:</ShortNote>
        <DocumentTitle>{title}</DocumentTitle>
        <ShortNote>
          Die Vorschau dient der Orientierung und weicht möglicherweise von der
          Darstellung des Dokuments ab.
        </ShortNote>
      </Frame>
      <WhiteFrame>
        {atn.map(element => (
          <PreviewElement
            activeLang={activeLang}
            element={element}
            key={element.id}
          />
        ))}
      </WhiteFrame>
    </Root>
  );
};

export default PreviewContent;
