// @flow

import React from "react";
import styled from "@emotion/styled";

import { isEmpty, debounce } from "../../helpers";

import ClearZone from "./ClearZone";

const All = styled("div")`
  position: relative;
  width: ${({ inline, large }) =>
    large ? "100%" : inline ? "initial" : "100%"};
  display: ${({ inline }) => (inline ? "inline-block" : "block")};
`;

const InputEl = styled("input")`
  color: #024761;
  font-weight: 700;
  outline-width: 0;
  padding: ${({ size }) =>
    size === "L" ? "12px 30px 12px 8px" : "8px 30px 8px 8px"};
  font-size: ${({ size }) => (size === "L" ? "20px" : "16px")};
  line-height: ${({ size }) => (size === "L" ? "20px" : "16px")};
  border-radius: 3px;
  border: ${({ noBorder }) => (noBorder ? "none" : "1px solid #ddd")};
  width: ${({ inline, large }) =>
    large ? "100%" : inline ? "initial" : "100%"};
  &::placeholder {
    color: #aaa;
  }
`;

const Append = styled("span")`
  position: absolute;
  top: 1px;
  right: 30px;
  font-size: 15px;
`;

type ValueType = ?(number | string);

type PropsType = {
  className?: string,
  size?: string,
  inputType: string,
  placeholder?: string,
  value: ValueType,
  defaultValue?: ValueType,
  inline?: boolean,
  clearable?: boolean,
  inputProps?: Object,
  className?: string,
  containerStyle?: Object,
  noBorder?: boolean,
  append?: any,
  staticElement: Boolean,
  onChange: (value: ValueType) => void
};

type StateType = {
  value: ?(number | string)
};

class Input extends React.Component<PropsType, StateType> {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
    };

    // onChange updates Redux state.
    // Debouncing this helps us make less frequent updates.
    this.debouncedChange = debounce(this.props.onChange, 250);
  }

  componentDidMount() {
    const { onChange, defaultValue } = this.props;

    if (!this.state.value && defaultValue) {
      this.setState({ value: defaultValue }, () => onChange(defaultValue));
    }
  }

  componentDidUpdate(prevProps) {
    // The value update came in from outside, not from within.
    // --> update the state accordingly.
    // (Introduced this, because document header is changed
    //   in Redux after mounting this input component)
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
      this.setState({ value: this.props.value });
    }
  }

  debouncedOnChange = e => {
    const val = e.target.value;

    this.setState({ value: val }, () => this.debouncedChange(val));
  };

  onClear = () => {
    this.props.onChange(this.props.inputType === "number" ? null : "");
  };

  render() {
    const {
      value,
      inputType,
      containerStyle,
      inline,
      className,
      size,
      placeholder,
      noBorder,
      append,
      clearable,
      inputProps,
      staticElement,
    } = this.props;

    const large = !!value && typeof value === "string" && value.length > 20;

    return (
      <All style={containerStyle} inline={inline} large={large}>
        <InputEl
          className={className}
          size={size}
          placeholder={placeholder}
          type={inputType}
          onChange={this.debouncedOnChange}
          value={this.state.value === null ? "" : this.state.value}
          noBorder={noBorder}
          inline={inline}
          large={large}
          disabled={staticElement}
          {...inputProps}
        />
        {!!append && <Append>{append}</Append>}
        {!!clearable && !isEmpty(value) && (
          <ClearZone size={size} onClick={this.onClear} />
        )}
      </All>
    );
  }
}

export default Input;
