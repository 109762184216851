// @flow

import React, { Component } from "react";
import styled from "@emotion/styled";

import { CHECKBOX } from "../../atnBase";

import ElementWithNesting from "./ElementWithNesting";

const Bubble = styled("div")`
  width: 20px;
  height: 20px;
  border: 2px dashed #ccc;
  margin: 0 8px 0 3px;
  flex-shrink: 0;
`;

class SelectMultiple extends Component {
  render() {
    return (
      <ElementWithNesting
        {...this.props}
        nestingKey="options"
        nestingElement={CHECKBOX}
        nestedContentKey="label"
        nestingIndicator={<Bubble />}
        allowsFurtherNesting={true}
        labelPlaceholder="Bsp: Wählen Sie Zutreffendes"
        optionPlaceholder="Option"
      />
    );
  }
}

export default SelectMultiple;
