// @flow

import * as React from "react";
import styled from "@emotion/styled";

const All = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 36px;
`;

const Label = styled("p")`
  margin: 0 10px 0 0;
  font-size: 14px;
  text-transform: uppercase;
  color: #666;
`;

type PropsType = {
  className?: string,
  label: string,
  children?: React.Node
};

const LabeledInlineElement = ({ className, label, children }: PropsType) => {
  return (
    <All className={className}>
      <Label>{label}</Label>
      {children}
    </All>
  );
};

export default LabeledInlineElement;
