// @flow

import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { DropTarget } from "react-dnd";
import isEqual from "react-fast-compare";

import {
  setElementAttr,
  removeElement,
  registerChild,
  addElementAndRegisterChild,
} from "../../actions";
import { makeSelectNestedElementIds, selectElement } from "../../selectors";

import Input from "../form/Input";
import IconButton from "../form/IconButton";
import EditorElement from "../main/EditorElement";
import FaIcon from "../main/FaIcon";
import AlternativeInput from "../styled/AlternativeInput";
import ActiveLang from "../styled/ActiveLang";

import { isStaticElement, placeholder } from "../../helpers/elementHelper";

const Option = styled("div")`
  margin: 0 0;
  border-radius: 5px;
  border: ${({ isOver, droppable }) =>
    isOver && droppable ? "3px solid #015270" : "3px solid transparent"};
`;

const Row = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Children = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 6px 0px 8px 32px;
  box-shadow: 0 0 25px 0 rgba(46, 61, 73, 0.2);
`;

const StyledInput = styled(Input)`
  ${AlternativeInput};
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 3px;
`;

function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    sourceId: monitor.getItem() ? monitor.getItem().id : null,
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
  };
}

const target = {
  canDrop(props, monitor) {
    const dragElement = monitor.getItem();
    if (isStaticElement(dragElement) || dragElement.element.static)
      return false;

    return true;
  },
  drop(props, monitor) {
    if (monitor.didDrop()) return;

    const id = monitor.getItem().id;
    if (id) {
      // Existing element dragged into nested option
      props.onAddChild(id);
    } else {
      // Element directly dragged from Drawer into nested option
      props.onAddElementAndChild(monitor.getItem().element);
    }
  },
};

class NestedOption extends Component {
  componentDidMount() {
    // Initialize with default value
    const { element, onMountNotify } = this.props;

    onMountNotify(element.id);
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  onChangeContent = (value) => {
    const { activeLang } = this.props;

    this.props.onChange(this.props.contentKey, {
      ...this.props.element.atn[this.props.contentKey],
      [activeLang]: value,
    });
  };

  onToggleAnamnesisReport = () => {
    this.props.onChange(
      "addToAnamnesisReport",
      !this.props.element.atn.addToAnamnesisReport
    );
    this.props.onChange("anamnesisReportLabel", null);
  };

  onChangeAnamnesisReportLabel = (value) => {
    this.props.onChange("anamnesisReportLabel", value);
  };

  render() {
    const {
      element,
      isRemovable,
      onRemoveOption,
      nestingIndicator,
      contentKey,
      multiLanguage,
      activeLang,
      placeholder: defaultPlaceholder,
      defaultValue,
      allowsFurtherNesting,
      connectDropTarget,
      children,
      isOverCurrent,
      nestingKey,
      onMountNotify,
      activeOnMount,
      staticElement,
    } = this.props;

    return (
      <Option
        ref={(instance) => {
          if (allowsFurtherNesting) connectDropTarget(instance);
        }}
        key={element.id}
        isOver={isOverCurrent}
        droppable={!staticElement}
      >
        <Row>
          {nestingIndicator}
          <Input
            key={activeLang}
            clearable={!staticElement}
            size="S"
            defaultValue={defaultValue}
            value={element.atn[contentKey][activeLang]}
            onChange={this.onChangeContent}
            placeholder={placeholder(
              this.props,
              contentKey,
              defaultPlaceholder
            )}
            inputType="text"
            append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
            staticElement={staticElement}
          />
          {allowsFurtherNesting &&
            element.atn.addToAnamnesisReport &&
            !staticElement && (
              <StyledInput
                size="S"
                containerStyle={{ paddingLeft: "5px" }}
                clearable={true}
                value={element.atn.anamnesisReportLabel}
                onChange={this.onChangeAnamnesisReportLabel}
                placeholder={`Anzeigetext Anamnesereport`}
                inputType="text"
              />
            )}
          {allowsFurtherNesting && !staticElement && (
            <StyledIconButton
              tabIndex="-1"
              onClick={this.onToggleAnamnesisReport}
            >
              <FaIcon center light icon="file-alt" />
            </StyledIconButton>
          )}
          {isRemovable && !staticElement && (
            <StyledIconButton tabIndex="-1" onClick={onRemoveOption}>
              <FaIcon center light icon="trash" />
            </StyledIconButton>
          )}
        </Row>
        {children.length > 0 && (
          <Children>
            {children.map((id) => (
              <EditorElement
                key={id}
                elementId={id}
                nestedInKey={nestingKey}
                onMountNotify={onMountNotify}
                activeOnMount={activeOnMount}
              />
            ))}
          </Children>
        )}
      </Option>
    );
  }
}

const makeMapStateToProps = () => {
  const selectNestedElementsIds = makeSelectNestedElementIds();

  return (state, ownProps) => ({
    element: selectElement(state, ownProps),
    children: selectNestedElementsIds(state, ownProps),
  });
};

const makeMapDispatchToProps = (_, ownProps) => (dispatch) => ({
  onRemoveOption: () => dispatch(removeElement(ownProps.elementId)),
  onChange: (attr, value) =>
    dispatch(setElementAttr(ownProps.elementId, attr, value)),
  onAddElementAndChild: (element) =>
    dispatch(
      addElementAndRegisterChild(
        ownProps.elementId,
        element,
        ownProps.nestingKey
      )
    ),
  onAddChild: (childId) =>
    dispatch(registerChild(ownProps.elementId, childId, ownProps.nestingKey)),
});

export default connect(
  makeMapStateToProps,
  makeMapDispatchToProps,
  null,
  {
    areStatePropsEqual: (prev, next) => {
      return (
        isEqual(prev.element, next.element) &&
        isEqual(prev.children, next.children)
      );
    },
  }
)(DropTarget("EDITOR_ELEMENT", target, collect)(NestedOption));
