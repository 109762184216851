export default {
  id: "id_6fd05b73_480c_4945_a5ce_e42329711e9d",
  type: "yesNo",
  required: false,
  static: true,
  label: {
      "de": "Haben Sie Interesse an unserem Erinnerungsservice, um Ihre nächste Kontrolluntersuchung nicht zu verpassen?",
      "tr": "Yeni hastalıklardan sakınmak için düzenli ön tedbir muayenelerine ilgi duyuyor musunuz?",
      "ar": "هل أنت مهتم بالرعاية الوقائية المنتظمة للوقاية من الأمراض الجديدة؟",
      "fr": "Souhaitez-vous bénéficier des soins réguliers afin d’éviter toute nouvelle infection ?",
      "en": "Are you interested in regular appointments for preventing further disease?",
      "ru": " Вы заинтересованы в регулярных консультациях для предотвращения новых заболеваний?",
      "es": "¿Desea realizar cheques preventivos regularmente para evitar nuevas enfermedades?"
  },
  errorMessage: {
      "de": "Bitte wählen Sie aus",
      "en": "Please select",
      "fr": "Veuillez choisir",
      "es": "Seleccione por favor",
      "tr": "Lütfen seçin",
      "ru": "Пожалуйста выберите",
      "ar": "يرجى الاختيار"
  },
  style: {
    position: "left",
    optionsArrangement: "horizontal",
  },
  options: [
    {
      id: "id_044cd60a_fbee_499e_a276_4cf3f3451889",
      type: "radioButton",
      static: true,
      label: {
          "de": "Ja",
          "en": "Yes",
          "ar": "نعم",
          "tr": "Evet",
          "fr": "Oui",
          "ru": "Да",
          "es": "Sí"
      },
      children: [
        {
          id: "id_df2507ab_19da_4338_ac07_278818d9f2c7",
          type: "selectOne",
          required: true,
          static: true,
          label: {
              "de": "Wie können wir Sie am besten an den nächsten Vorsorgetermin erinnern?",
              "tr": "Bir sonraki randevunuzu size en iyi şekilde nasıl hatırlatabiliriz?",
              "ar": "ما هي أفضل طريقة يمكننا بها تذكيرك بموعد الرعاية التالي؟",
              "fr": "Comment pouvons-nous vous rappeler votre prochain rendez-vous ?",
              "en": "What is the best way to remind you of the next screening appointment?",
              "ru": "Как вам лучше напомнить о следующей консультации?",
              "es": "¿Cómo prefiere que le recordemos su próxima visita de control? "
          },
          errorMessage: {
              "de": "Bitte wählen Sie aus",
              "en": "Please select",
              "fr": "Veuillez choisir",
              "es": "Seleccione por favor",
              "tr": "Lütfen seçin",
              "ru": "Пожалуйста выберите",
              "ar": "يرجى الاختيار"
          },
          style: {
            position: "left",
            optionsArrangement: "horizontal",
          },
          dsWinFieldName: "recall",
          options: [
            {
              id: "id_daba28ec_06df_4d87_b5bf_39c0d2a7d80e",
              type: "radioButton",
              static: true,
              label: {
                  "de": "Brief",
                  "ar": "خطاب",
                  "tr": "Mektupla",
                  "fr": "Courrier",
                  "en": "letter",
                  "ru": "Почта",
                  "es": "Por carta"
              },
              dsWinFieldValue: "brief",
              children: [],
            },
            {
              id: "id_ad5a420f_08ce_4a75_8168_a9d67998502c",
              type: "radioButton",
              static: true,
              label: {
                  "de": "SMS",
                  "ar": " SMS رسالة نصية",
                  "tr": "SMS",
                  "fr": "SMS",
                  "en": "SMS",
                  "ru": "СМС",
                  "es": "SMS"
              },
              dsWinFieldValue: "sms",
              children: [],
            },
            {
              id: "id_0ab7ed43_4321_479a_9e13_3081d6572b11",
              type: "radioButton",
              static: true,
              label: {
                  "de": "E-Mail",
                  "ar": "البريد الإلكتروني",
                  "tr": "E-Posta",
                  "fr": "Adresse électronique",
                  "en": "e-mail",
                  "ru": "Электронная почта",
                  "es": "Correo electrónico"
              },
              dsWinFieldValue: "email",
              children: [],
            },
            {
              id: "id_83ca4168_ff28_4c78_831d_4d0a6e1854b9",
              type: "radioButton",
              static: true,
              label: {
                  "de": "Telefon",
                  "ar": "التليفون",
                  "tr": "Telefon",
                  "fr": "Téléphone",
                  "en": "telephone",
                  "ru": "Телефон",
                  "es": "Teléfono"
              },
              dsWinFieldValue: "telefon",
              children: [],
            },
          ],
        },
        {
          id: "id_7291622c_f6df_4cd7_932e_c1f161e4d886",
          type: "selectOne",
          required: true,
          static: true,
          label: {
              "de": "Wer soll kontaktiert werden?",
              "en": "Who needs to be contacted?",
              "ar": "من هي الجهة أو الشخص الذي ينبغي الاتصال به؟",
              "tr": "Kiminle iletişime geçilmeli?",
              "fr": "Qui faut-il contacter ?",
              "ru": "К кому следует обращаться?",
              "es": "¿Con quién hay que ponerse en contacto?"
          },
          errorMessage: {
              "de": "Bitte wählen Sie aus",
              "en": "Please select",
              "fr": "Veuillez choisir",
              "es": "Seleccione por favor",
              "tr": "Lütfen seçin",
              "ru": "Пожалуйста выберите",
              "ar": "يرجى الاختيار"
          },
          style: {
            position: "left",
            optionsArrangement: "horizontal",
          },
          dsWinFieldName: "recallTo",
          options: [
            {
              id: "id_24170d8b_38b9_4f7e_bb7b_de3339bc1149",
              type: "radioButton",
              static: true,
              label: {
                  "de": "Patient",
                  "ar": "المريض",
                  "tr": "Hasta",
                  "fr": "patient",
                  "en": "Patient",
                  "ru": "Пациент",
                  "es": "Paciente"
              },
              dsWinFieldValue: "patient",
              children: [],
            },
            {
              id: "id_ae1d76a9_ac46_4665_88a1_d2df2c4f1cc4",
              type: "radioButton",
              static: true,
              label: {
                  "de": "Versicherter",
                  "en": "Insured party",
                  "ar": "المُؤمن عليه/حامل التأمين",
                  "tr": "Sigortalı",
                  "fr": "assuré",
                  "ru": "Застрахованное лицо",
                  "es": "Persona asegurada"
              },
              dsWinFieldValue: "versicherter",
              children: [],
            },
          ],
        },
      ],
    },
    {
      id: "id_07c6bb4a_da6c_4d64_a371_df9e549e170b",
      static: true,
      type: "radioButton",
      label: {
          "de": "Nein",
          "en": "No",
          "ar": "لا",
          "tr": "Hayır",
          "fr": "Non",
          "ru": "Нет",
          "es": "No"
      },
      dsWinFieldName: "recall",
      dsWinFieldValue: "",
      children: [],
    },
  ],
};
