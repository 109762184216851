// @flow

import type { StateType as SettingsType } from "../reducers/settingsReducer";
import type { StateType as ElementsType } from "../reducers/elementsReducer";

import { TRANSLATABLE_ATN_KEYS, NESTED_ELEMENT_KEYS } from "../atnConfig";

import { objectWithoutKey, compose } from "./index";

export function buildATN(elements: ElementsType, settings: SettingsType) {
  return compose(
    resolveNesting,
    stripDeselectedLanguages(settings),
    stripAnamnesisReport,
    stripInternalProperties
  )(elements);
}

function resolveNesting(elements) {
  return elements
    .map(resolveNestedNode)
    .filter(element => !element.parentId)
    .map(objectWithoutKey("parentId"));
}

function childFromList(list, child) {
  return list.find(el => el.id === child.id);
}

// This is basically a post-order depth first tree traversal
// => We "resolve" (recurse) each child from the flat list first,
//    before we spread (operate) it into its parent's children
function resolveNestedNode(element, i, nodes) {
  return {
    ...element,
    ...NESTED_ELEMENT_KEYS.filter(nestingKey =>
      element.hasOwnProperty(nestingKey)
    ).reduce((all, nestingKey) => {
      all[nestingKey] = element[nestingKey].map(child => ({
        ...objectWithoutKey("parentId")(
          resolveNestedNode(childFromList(nodes, child), i, nodes)
        )
      }));

      return all;
    }, {})
  };
}

/*
  To make sure all the anamnesis report label stuff is really working correctly
  TODO: Remove the addToAnamnesisReport flag entirely
*/
function stripAnamnesisReport(elements) {
  return elements.map(el => {
    return el.anamnesisReportLabel
      ? {
          ...el,
          addToAnamnesisReport: true,
          anamnesisReportLabel: el.anamnesisReportLabel
        }
      : compose(
          objectWithoutKey("anamnesisReportLabel"),
          objectWithoutKey("addToAnamnesisReport")
        )(el);
  });
}

/*
  Strips all other meta information from element like display name, color, and more
  Only keeps atn and relevant ids
*/
function stripInternalProperties(elements) {
  return elements.map(el => ({
    id: el.id,
    parentId: el.parentId,
    ...el.atn
  }));
}

const stripDeselectedLanguages = settings => elements => {
  return elements.map(el => stripDeselectedLanguagesOfElement(el, settings));
};

function stripDeselectedLanguagesOfElement(obj, settings) {
  const strippedObj = Object.keys(obj).reduce((result, key) => {
    result[key] = obj[key];

    if (TRANSLATABLE_ATN_KEYS.includes(key)) {
      result[key] = Object.keys(obj[key])
        .filter(langKey => settings.languages.includes(langKey))
        .reduce((all, langKey) => {
          all[langKey] = obj[key][langKey];

          return all;
        }, {});
    }

    return result;
  }, {});

  return !!obj.children && obj.children.length !== 0
    ? {
        ...strippedObj,
        children: obj.children.map(child =>
          stripDeselectedLanguagesOfElement(child, settings)
        )
      }
    : strippedObj;
}
