import React from "react";
import styled from "@emotion/styled";

import PositionPicker from "../style-form/PositionPicker";
import SizePicker from "../style-form/SizePicker";

const Root = styled("div")`
  margin: 0;
`;

const TextAreaInput = props => {
  return (
    <Root>
      <SizePicker
        label="Breite"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
    </Root>
  );
};

export default TextAreaInput;
