import React from "react";
import styled from "@emotion/styled";

const Container = styled("div")`
  display: inline-block;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  font-size: 22px;
  width: 25px;
  height: 25px;
  border: 2px solid #024761;
  border-radius: 3px;
  box-sizing: content-box;
`;

const Checkmark = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #024761;

  &:after {
    content: "";
    position: absolute;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const PreviewCheckbox = ({ checked, className }) => (
  <Container className={className}>{checked && <Checkmark />}</Container>
);

export default PreviewCheckbox;
