import React, { Component } from "react";

import Input from "../form/Input";
import ActiveLang from "../styled/ActiveLang";

import { placeholder } from "../../helpers/elementHelper";

const inputProps = { full: true };

class Heading extends Component {
  render() {
    const {
      element,
      onChange,
      multiLanguage,
      settings: { activeLang }
    } = this.props;

    return (
      <Input
        clearable={true}
        key={activeLang}
        size="L"
        inputProps={inputProps}
        value={element.atn.content[activeLang]}
        onChange={value =>
          onChange("content", {
            ...element.atn.content,
            [activeLang]: value
          })
        }
        placeholder={placeholder(this.props, "content", "Überschrift")}
        inputType="text"
        append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
      />
    );
  }
}

export default Heading;
