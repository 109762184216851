// @flow

import React from "react";

import RadioButton from "../form/RadioButton";
import StylePicker from "./StylePicker";

type PropsType = {
  label: string,
  value: { size: string },
  onChange: (string, string) => void
};

const PositionPicker = (props: PropsType) => {
  return (
    <StylePicker label={props.label}>
      <RadioButton
        size="S"
        value={props.value.position}
        options={[
          { label: "Oben", value: "top" },
          { label: "Links", value: "left" }
        ]}
        onChange={value => props.onChange("position", value)}
      />
    </StylePicker>
  );
};

export default PositionPicker;
