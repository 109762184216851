// @flow

import React from "react";
import styled from "@emotion/styled";

const All = styled("div")`
  margin: 0;
`;

const Option = styled("span")`
  font-size: ${({ size }) => (size === "S" ? "16px" : "18px")};
  display: inline-block;
  padding: 4px 10px;
  cursor: pointer;
  transition: color 0.1s, background-color 0.1s;
  color: ${({ active }) => (active ? "#024761" : "#999")};
  font-weight: 700;
  border: 1px solid #aaa;
  background-color: ${({ active }) => (active ? "#fff" : "#fafafa")};

  margin-left: -1px;

  &:first-of-type {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:hover {
    background-color: ${({ active }) => (active ? "#fff" : "#f0f0f0")};
  }
`;

type OptionsType = {
  label: string,
  value: string
};

type PropsType = {
  size?: string,
  value: string,
  onChange: string => void,
  options: OptionsType[]
};

const RadioButton = (props: PropsType) => {
  return (
    <All>
      {props.options.map(({ value, label }) => (
        <Option
          size={props.size}
          active={props.value === value}
          key={value}
          onMouseDown={e => {
            e.preventDefault(); // No need to blur other input elements

            if (value !== props.value) props.onChange(value);
          }}
        >
          {label}
        </Option>
      ))}
    </All>
  );
};

export default RadioButton;
