// @flow

import * as React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";

import type { StateType as ElementsType } from "../../reducers/elementsReducer";

import { getRootOrder } from "../../selectors";

import EditorElement from "./EditorElement";
import AreaEmpty from "./AreaEmpty";

const Root = styled("div")`
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({ category }) =>
    category === 1 ? "#fafaff" : category === 2 ? "#fafffa" : "fafafa"};
`;

const Column = styled("div")`
  flex-grow: 1;
  background-color: white;
  box-shadow: 0 0 25px 0 rgba(46, 61, 73, 0.2);
  border: 1px solid #ccc;
`;

type PropsType = {
  dirty: boolean,
  loading: boolean,
  className?: string,
  editorElements: ElementsType
};

type StateType = {
  // Used to enable scrolling to elements, once all elements have been loaded once
  // (Can't rely on componentDidMount here, because children are connected components)
  editorReady: boolean
};

class Editor extends React.Component<PropsType, StateType> {
  root: { current: null | HTMLDivElement };

  elementsReady: Number[];

  state = {
    editorReady: false
  };

  constructor(props) {
    super(props);

    this.root = React.createRef();
    this.elementsReady = [];
    this.onBeforeUnload = this.onBeforeUnload.bind(this);
  }

  componentDidMount() {
    window.addEventListener("beforeunload", this.onBeforeUnload);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps) {
    this.elementsReady = [];
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  scrollToEl = ref => {
    this.root.current &&
      this.root.current.scrollTo({
        top: Math.max(ref.current.offsetTop - 200, 0),
        behavior: "smooth" // Optional, adds animation
      });
  };

  notifyMount = i => {
    this.elementsReady.push(i);

    if (
      this.props.editorElements.length > 0 &&
      this.elementsReady.length === this.props.totalElements
    ) {
      this.setState({ editorReady: true });
      this.elementsReady = [];
    }
  };

  onBeforeUnload(event) {
    if (!this.props.dirty) return;

    // Warn about unsaved changes
    event.preventDefault();
    event.returnValue = "";
  }

  render() {
    const { className, editorElements, loading, category } = this.props;
    const { editorReady } = this.state;

    return (
      <Root
        ref={this.root}
        className={className}
        category={category}
        id="editor"
      >
        {loading && (
          <AreaEmpty showArrow={false}>
            Das Dokument wird geladen. Einen Moment bitte.
          </AreaEmpty>
        )}
        {!loading && editorElements.length === 0 && (
          <AreaEmpty showArrow={true}>
            Ziehen Sie Elemente per Drag & Drop hier hinein.
          </AreaEmpty>
        )}
        {editorElements.length > 0 && (
          <Column>
            {editorElements.map(id => (
              <EditorElement
                key={id}
                elementId={id}
                onMountNotify={this.notifyMount}
                activeOnMount={editorReady}
                onScrollToMe={this.scrollToEl}
              />
            ))}
          </Column>
        )}
      </Root>
    );
  }
}

export default connect(state => ({
  category: state.documentHeader.category,
  dirty: state.documentStatus.dirty,
  loading: state.documentStatus.docLoad.loading,
  editorElements: getRootOrder(state),
  totalElements: Object.keys(state.elements.data).length
}))(Editor);
