import React from "react";
import styled from "@emotion/styled";

import ArrangementPicker from "../style-form/ArrangementPicker";
import PositionPicker from "../style-form/PositionPicker";

const Root = styled("div")`
  margin: 0;
`;

const SelectOne = props => {
  return (
    <Root>
      <ArrangementPicker
        label="Anordnung der Punkte"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
    </Root>
  );
};

export default SelectOne;
