// @flow

export type StateType = {
  multiLanguage: boolean,
  download: boolean
};

//
// Simply toggle the feature here
//
export const initialState: StateType = {
  multiLanguage: true,
  download: false
};

function reducer(state: StateType = initialState, action: Object): StateType {
  switch (action.type) {
    case "FEATURE_TOGGLE":
      const { feature } = action.payload;

      return { ...state, [feature]: !state[feature] };
    default:
      return state;
  }
}

export default reducer;
