import styled from "@emotion/styled";

const ComponentTitle = styled("h2")`
  margin: 0;
  padding: 0 0 12px;
  border-bottom: 1px solid #ccc;
  font-size: 18px;
  font-weight: 300;
`;

export default ComponentTitle;
