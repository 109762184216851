import styled from "@emotion/styled";

const GroupTitle = styled("p")`
  margin: 0 2px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #666;
`;

export default GroupTitle;
