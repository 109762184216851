import * as ATN from "./atnBase";

export const AVAILABLE_LANGUAGES = [
  "en",
  "de",
  "fr",
  "es",
  "tr",
  "pl",
  "ru",
  "ch",
  "ja",
  "ar",
];

export const TRANSLATABLE_ATN_KEYS = [
  "content",
  "caption",
  "label",
  "placeholder",
  "listItem",
  "errorMessage",
];

export const ELEMENT_DEFAULT_CHILDREN = {
  selectOne: {
    nestingKey: "options",
    children: [ATN.RADIO_BUTTON, ATN.RADIO_BUTTON],
  },
  yesNo: {
    nestingKey: "options",
    children: [ATN.RADIO_BUTTON, ATN.RADIO_BUTTON],
  },
  selectMultiple: {
    nestingKey: "options",
    children: [ATN.CHECKBOX, ATN.CHECKBOX],
  },
  list: {
    nestingKey: "items",
    children: [ATN.LIST_ITEM, ATN.LIST_ITEM],
  },
};

export const NON_INTERACTIVE_TYPES = ["radioButton", "checkbox", "listItem"];

export const NESTED_ELEMENT_KEYS = ["children", "options", "items"];

export const DRAWER_ELEMENTS = [
  // Organizational
  [ATN.GROUP, ATN.SEPARATOR, ATN.SPACER],

  // Presentational
  [ATN.HEADLINE, ATN.TEXT, ATN.PARAGRAPH, ATN.LIST, ATN.IMAGE, ATN.VIDEO],

  // Input
  [
    ATN.TEXT_INPUT,
    ATN.TEXT_AREA_INPUT,
    ATN.YES_NO,
    ATN.SELECT_ONE,
    ATN.SELECT_MULTIPLE,
    ATN.CAMERA_INPUT,
    ATN.RECALL,
    ATN.SIGNATURE,
  ],
];
