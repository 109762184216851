import { combineReducers } from "redux";

import elements from "./elementsReducer";
import settings from "./settingsReducer";
import activeElement from "./activeElementReducer";
import view from "./viewReducer";
import feature from "./featureReducer";
import documentStatus from "./documentStatusReducer";
import documentHeader from "./documentHeaderReducer";
import preview from "./previewReducer";
import meta from "./metaReducer";
import practice from "./practiceReducer";

export default combineReducers({
  elements,
  settings,
  activeElement,
  view,
  feature,
  documentStatus,
  documentHeader,
  preview,
  meta,
  practice
});
