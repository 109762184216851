import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { DropTarget } from "react-dnd";

import { registerChild, addElementAndRegisterChild } from "../../actions";
import { makeSelectNestedElementIds } from "../../selectors";
import { isStaticElement } from "../../helpers/elementHelper";

import EditorElement from "../main/EditorElement";

const Root = styled("div")`
  width: 100%;
  padding: 0 0 10px;
`;

const Inner = styled("div")`
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 0 25px 0 rgba(46, 61, 73, 0.2);
  background-color: ${({ isOver }) => (isOver ? "#e0e0e0" : "transparent")};
`;

const Column = styled("div")`
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const Description = styled("div")`
  padding: 15px;
`;

const groupTarget = {
  canDrop(props, monitor) {
    const dragElement = monitor.getItem();
    if (isStaticElement(dragElement) || dragElement.element.static)
      return false;

    const elementId = dragElement.element.id;

    return (
      // Element is not the group itself
      props.element.id !== elementId &&
      // Element is not already in the group
      !props.children.find((child) => child.id === elementId)
    );
  },

  hover(props, monitor, component) {
    return;
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) return;

    const elementId = monitor.getItem().id;

    if (elementId) {
      // Existing element dragged into group
      props.onMoveElementIntoGroup(elementId);
    } else {
      // Not an initialized element yet
      props.onAddElementAndMoveIntoGroup(monitor.getItem().element);
    }

    return { moved: true };
  },
};

function collectDrop(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

class Group extends Component {
  render() {
    const {
      children,
      connectDropTarget,
      isOverCurrent,
      onMountNotify,
      activeOnMount,
    } = this.props;

    return (
      <Root ref={(instance) => connectDropTarget(instance)}>
        <Inner isOver={isOverCurrent}>
          {children.length > 0 && (
            <Column>
              {children.map((id) => (
                <EditorElement
                  key={id}
                  elementId={id}
                  nestedInKey="children"
                  onMountNotify={onMountNotify}
                  activeOnMount={activeOnMount}
                />
              ))}
            </Column>
          )}
          <Description>
            Elemente, die Sie hier ablegen, werden als Spalten angezeigt
          </Description>
        </Inner>
      </Root>
    );
  }
}

const makeMapStateToProps = () => {
  const selectNestedElementsIds = makeSelectNestedElementIds();

  return (state, ownProps) => ({
    children: selectNestedElementsIds(state, {
      ...ownProps,
      nestingKey: "children",
    }),
  });
};

export default connect(
  makeMapStateToProps,
  (dispatch, ownProps) => ({
    onAddElementAndMoveIntoGroup: (element) =>
      dispatch(
        addElementAndRegisterChild(ownProps.element.id, element, "children")
      ),
    onMoveElementIntoGroup: (childId) =>
      dispatch(registerChild(ownProps.element.id, childId, "children")),
  })
)(DropTarget("EDITOR_ELEMENT", groupTarget, collectDrop)(Group));
