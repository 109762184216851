// @flow

import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import Hotkeys from "react-hot-keys";

import { setPreview } from "../../actions";

import FaIcon from "../main/FaIcon";

import BasicButton from "./BasicButton";

const Btn = styled(BasicButton)`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const PreviewButton = ({ className, onShowPreview }) => (
  <Btn className={className} size="L" onClick={onShowPreview}>
    <span>Vorschau</span>
    <Hotkeys keyName="alt+v" onKeyUp={onShowPreview} />
  </Btn>
);

export default connect(
  () => ({}),
  dispatch => ({
    onShowPreview: () => dispatch(setPreview(true))
  })
)(PreviewButton);
