import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

import { DRAWER_ELEMENTS } from "../../atnConfig";
import { loadATN } from "../../helpers/atnLoader";
import { addElement, addElementAndMove, addStaticElement } from "../../actions";
import { selectActiveElement } from "../../selectors";

import FaIcon from "../main/FaIcon";
import ComponentTitle from "../styled/ComponentTitle";
import GroupTitle from "../styled/GroupTitle";

import DrawerElement from "./DrawerElement";

const Root = styled("div")`
  background-color: #f3f3f3;
  padding: 15px 20px;
  height: 100%;
  overflow-y: auto;
`;

const DrawerGroup = styled("div")`
  &:first-of-type {
    padding-top: 12px;
  }
  margin-bottom: 0px;
  padding-bottom: 10px;
`;

const GROUP_TITLES = [
  "Struktur-Elemente",
  "Anzeige-Elemente",
  "Eingabe-Elemente",
];

class Drawer extends Component {
  shouldComponentUpdate(nextProps) {
    // Optimization, in an essence:
    // Don't update when someone is typing and only changes
    // the activeElement's text contents OR if an element is added/removed
    const { elements } = this.props;

    if (
      Object.keys(elements).length !== Object.keys(nextProps.elements).length
    ) {
      return true;
    }

    return (
      this.props.className !== nextProps.className ||
      this.props.nestedInKey !== nextProps.nestedInKey ||
      (!!this.props.activeElement && !nextProps.activeElement) ||
      (!this.props.activeElement && !!nextProps.activeElement) ||
      (!!this.props.activeElement &&
        !!nextProps.activeelement &&
        this.props.activeElement.id !== !!nextProps.activeElement.id)
    );
  }

  shouldBeDisabled(element) {
    if (!element.static) return false;
    const { elements } = this.props;
    const staticRootId = element.atn[0].id;
    return !!elements[staticRootId];
  }

  render() {
    return (
      <Root className={this.props.className} data-testid="drawer">
        <ComponentTitle>
          <FaIcon left size="L" icon="th-large" />
          Elemente
        </ComponentTitle>
        {DRAWER_ELEMENTS.map((group, i) => (
          <DrawerGroup key={`group-${i}`}>
            <GroupTitle>{GROUP_TITLES[i]}</GroupTitle>
            {group.map((element) => {
              return (
                <DrawerElement
                  key={element.name}
                  element={element}
                  disabled={this.shouldBeDisabled(element)}
                  onAddElement={() =>
                    this.props.onAddElement(
                      element,
                      this.props.activeElement,
                      this.props.nestedInKey
                    )
                  }
                />
              );
            })}
          </DrawerGroup>
        ))}
      </Root>
    );
  }
}

export default connect(
  (state) => ({
    nestedInKey: state.activeElement.nestedInKey,
    activeElement: selectActiveElement(state),
    elements: state.elements.data,
  }),
  (dispatch) => ({
    onAddElement: (element, activeElement, nestedInKey) => {
      if (element.static === true) {
        const { elements, rootOrder } = loadATN(element.atn);
        dispatch(addStaticElement(elements, rootOrder));
      } else if (activeElement) {
        dispatch(
          addElementAndMove(element, activeElement.id, true, nestedInKey)
        );
      } else {
        dispatch(addElement(element));
      }
    },
  })
)(Drawer);
