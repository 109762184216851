// @flow

import * as React from "react";
import styled from "@emotion/styled";

const Root = styled("button")`
  padding: 5px 12px;
  font-size: ${({ size }) => (size === "L" ? "24px" : "14px")};
  line-height: 14px;
  color: #333;
  border: ${({ active }) =>
    active ? "1px solid #015270" : "1px solid transparent"};
  border-radius: 5px;
  line-height: 1;
  outline-width: 0;
  cursor: pointer;
  transition: background-color 0.1s;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    transition: color 0.1s;
    color: ${({ active }) => (active ? "#444444" : "#bbbbbb")};
  }

  &:hover {
    background-color: ${({ active }) => (active ? "#f5f5f5" : "#f5f5f5")};
    svg {
      color: #444;
    }
  }
`;

type PropsType = {
  children: React.Node,
  size?: string
};

const IconButton = ({ children, ...props }: PropsType) => {
  return <Root {...props}>{children}</Root>;
};

export default IconButton;
