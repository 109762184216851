// @flow

import React from "react";
import styled from "@emotion/styled";

import FaIcon from "../main/FaIcon";

const Root = styled("span")`
  position: absolute;
  top: ${({ size }) => (size === "L" ? "14px" : "10px")};
  right: 14px;
  cursor: pointer;
  transition: opacity 0.1s;
  color: #333;
  opacity: 0.5;
  font-size: ${({ size }) => (size === "L" ? "16px" : "14px")};

  &:hover {
    opacity: 0.8;
  }
`;

export default ({ size, onClick, className }) => (
  <Root
    className={className}
    size={size}
    title="Wert entfernen"
    aria-label="Wert entfernen"
    onClick={onClick}
  >
    <FaIcon icon="times" />
  </Root>
);
