import * as React from "react";

import ToggleButton from "react-toggle-button";

const thumbStyle = {
  height: "18px",
  width: "18px"
};

const trackStyle = {
  width: "40px",
  height: "20px"
};

const containerStyle = {
  width: "40px"
};

type PropsType = {
  value?: boolean,
  className?: string,
  onChange: boolean => void
};

export default (props: PropsType) => (
  <div className={props.className} data-testid="toggle-btn">
    <ToggleButton
      value={props.value || false}
      onToggle={value => props.onChange(!value)}
      thumbAnimateRange={[1, 21]}
      colors={{
        activeThumb: {
          base: "#fff"
        },
        inactiveThumb: {
          base: "#fff"
        },
        active: {
          base: "#015270",
          hover: "#116280"
        },
        inactive: {
          base: "#888",
          hover: "#999"
        }
      }}
      inactiveLabel=""
      activeLabel=""
      containerStyle={containerStyle}
      thumbStyle={thumbStyle}
      trackStyle={trackStyle}
    />
  </div>
);
