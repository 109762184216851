// @flow

import React from "react";
import Select from "../form/Select";

import { translate } from "../../services/i18n";

const DS_WIN_FIELD_NAMES = [
  "anrede",
  "titel",
  "vorname",
  "nachname",
  "ort",
  "land",
  "telefon",
  "telefon2",
  "mobil",
  "email",
  "strasse",
  "strassenzusatz",
  "geburtsdatum",
  "versicherter_anrede",
  "versicherter_titel",
  "versicherter_vorname",
  "versicherter_nachname",
  "versicherter_ort",
  "versicherter_land",
  "versicherter_telefon",
  "versicherter_strasse",
  "versicherter_strassenzusatz",
  "versicherter_geburtsdatum"
];

const DS_WIN_FIELD_OPTIONS = DS_WIN_FIELD_NAMES.map(item => {
  return [item, translate(`dsWinFieldName.${item}`)];
});

const DsWinFieldNameSelect = props => {
  return (
    <Select
      {...props}
      defaultValue={DS_WIN_FIELD_NAMES[0]}
      options={DS_WIN_FIELD_OPTIONS}
      title={translate("dsWinFieldNameSelect.patientDataChange")}
      value={DS_WIN_FIELD_NAMES.includes(props.value) ? props.value : null}
    />
  );
};

export default DsWinFieldNameSelect;
