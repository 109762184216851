// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

import { buildATN } from "../../helpers/atnRenderer";
import { selectElementsInOrder } from "../../selectors";
import { setPreview } from "../../actions";

import BasicButton from "../form/BasicButton";
import FaIcon from "./FaIcon";
import PreviewContent from "./PreviewContent";
import EscAble from "./EscAble";

const BigCloseIcon = styled(BasicButton)`
  position: fixed;
  z-index: 25;
  top: 20px;
  right: 20px;
  font-size: 26px;
`;

const StyledFaIcon = styled(FaIcon)`
  font-size: 26px;
`;

type PropsType = {
  show: boolean,
  title: string,
  atn: object,
  onClose: () => void,
  activeLang: string,
  practice: object
};

class Preview extends Component<PropsType> {
  render() {
    const { show, title, atn, onClose, activeLang, practice } = this.props;

    const replacePlaceholders = () => {
      let atnString = JSON.stringify(atn);

      const placeholderConversionTable = {
        "{{praxis_logo}}": practice.logo_url,
        "{{praxis_stadt}}": practice.city,
        "{{praxis_name}}": practice.name,
        "{{praxis_adresse_1}}": practice.address1,
        "{{praxis_postleitzahl}}": practice.zipcode,
        "{{praxis_telefon}}": practice.phone,
        "{{praxis_email}}": practice.email,
        "{{praxis_dsgvo_factoring_provider}}":
          practice.dgsvo_factoring_provider,
        "{{praxis_datenschutzbeauftragter}}":
          practice.dgsvo_data_protection_officer_name,
        "{{praxis_datenschutzbeauftragter_adresse}}":
          practice.dgsvo_data_protection_officer_address,
        "{{praxis_datenschutzbeauftragter_kontaktdaten}}":
          practice.dgsvo_data_protection_officer_contact
      };

      const allPlaceholders = Object.keys(placeholderConversionTable);
      allPlaceholders.forEach(placeholder => {
        if (placeholderConversionTable[placeholder]) {
          atnString = atnString.replace(
            placeholder,
            this.escapeDoubleQuotes(placeholderConversionTable[placeholder])
          );
        }
      });

      return JSON.parse(atnString);
    };

    let personalizedAtn = atn;
    if (practice) {
      personalizedAtn = replacePlaceholders();
    }

    return (
      <>
        {show && (
          <EscAble onEscPressed={onClose}>
            <PreviewContent
              activeLang={activeLang}
              atn={personalizedAtn}
              header={
                <BigCloseIcon size="L" onClick={onClose}>
                  Schließen
                  <StyledFaIcon icon="times" right={true} />
                </BigCloseIcon>
              }
              practice={practice}
              title={title}
            />
          </EscAble>
        )}
      </>
    );
  }

  escapeDoubleQuotes(string) {
    return string.replace(/\\([\s\S])|(")/g, "\\$1$2");
  }
}

export default connect(
  state => ({
    title: state.documentHeader.title,
    show: state.preview.show,
    atn:
      state.preview.show &&
      buildATN(selectElementsInOrder(state), state.settings),
    activeLang: state.settings.activeLang,
    practice: state.practice
  }),
  dispatch => ({
    onClose: () => dispatch(setPreview(false))
  })
)(Preview);
