// @flow

import React from "react";

import RadioButton from "../form/RadioButton";

import StylePicker from "./StylePicker";

type PropsType = {
  label: string,
  value: { size: string },
  onChange: (string, string) => void
};

const AlignmentPicker = (props: PropsType) => {
  return (
    <StylePicker label={props.label}>
      <RadioButton
        value={props.value.align}
        options={[
          { label: "Links", value: "left" },
          { label: "Mitte", value: "center" },
          { label: "Rechts", value: "right" }
        ]}
        onChange={value => props.onChange("align", value)}
      />
    </StylePicker>
  );
};

export default AlignmentPicker;
