import React from "react";
import styled from "@emotion/styled";

import AlignmentPicker from "../style-form/AlignmentPicker";
import HeadingSizePicker from "../style-form/HeadingSizePicker";

const Root = styled("div")`
  margin: 0;
`;

const Heading = props => {
  return (
    <Root>
      <HeadingSizePicker
        label="Größe der Überschrift"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
      <AlignmentPicker
        label="Positionierung"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
    </Root>
  );
};

export default Heading;
