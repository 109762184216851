// @flow

import React from "react";

import RadioButton from "../form/RadioButton";
import StylePicker from "./StylePicker";

type PropsType = {
  label: string,
  value: { size: string },
  onChange: (string, string) => void
};

const OrderPicker = (props: PropsType) => {
  return (
    <StylePicker label={props.label}>
      <RadioButton
        value={props.value.order}
        options={[
          { label: "Nummeriert", value: "ordered" },
          { label: "Stichpunkte", value: "unordered" }
        ]}
        onChange={value => props.onChange("order", value)}
      />
    </StylePicker>
  );
};

export default OrderPicker;
