// @flow

import type { ElementsType, RootOrderType } from "../reducers/elementsReducer";

import { NESTED_ELEMENT_KEYS } from "../atnConfig";

import {
  STD_ERROR_MESSAGE_INPUT,
  STD_ERROR_MESSAGE_SIGNATURE,
  STD_ERROR_MESSAGE_OPTION,
  STD_ERROR_MESSAGE_CAMERA_INPUT,
  SIGNATURE_TEXT,
  NAME_FOR_TYPE,
  COLOR_FOR_TYPE
} from "../atnBase";

import { objectWithoutKey, compose } from "./index";

const initializeWithDefaultTexts = () => element => {
  // Deep copy, just to allow us to use mutation
  const newElement = JSON.parse(JSON.stringify(element));

  if (newElement.errorMessage) {
    switch (newElement.type) {
      case "signature":
        newElement.errorMessage = {
          ...STD_ERROR_MESSAGE_SIGNATURE,
          ...newElement.errorMessage
        };
        break;
      case "yesNo":
      case "selectOne":
      case "selectMultiple":
        newElement.errorMessage = {
          ...STD_ERROR_MESSAGE_OPTION,
          ...newElement.errorMessage
        };
        break;
      case "textInput":
      case "textAreaInput":
        newElement.errorMessage = {
          ...STD_ERROR_MESSAGE_INPUT,
          ...newElement.errorMessage
        };
        break;
      case "cameraInput":
        newElement.errorMessage = {
          ...STD_ERROR_MESSAGE_CAMERA_INPUT,
          ...newElement.errorMessage
        };
        break;
      default:
        break;
    }
  }

  if (newElement.type === "signature") {
    newElement.infoLabel = { ...SIGNATURE_TEXT, ...newElement.infoLabel };
  }

  return newElement;
};

function createNode(element) {
  const directChildren = NESTED_ELEMENT_KEYS.reduce((childrenObj, key) => {
    if (element.hasOwnProperty(key)) {
      childrenObj[key] = element[key].map(child => ({ id: child.id }));
    }

    return childrenObj;
  }, {});

  const node = {
    id: element.id,
    name: NAME_FOR_TYPE[element.type],
    color: COLOR_FOR_TYPE[element.type],
    atn: {
      ...compose(
        objectWithoutKey("id"),
        objectWithoutKey("parentId"),
        ...NESTED_ELEMENT_KEYS.map(key => objectWithoutKey(key)),
        initializeWithDefaultTexts()
      )(element),
      ...directChildren
    }
  };

  return element.parentId ? { ...node, parentId: element.parentId } : node;
}

export function loadATN(
  atn_v2: []
): { rootOrder: RootOrderType, elements: ElementsType } {
  if (atn_v2.length === 0) return { rootOrder: [], elements: {} };

  const rootOrder = atn_v2.map(el => el.id);

  const rootElements = atn_v2.reduce((all, element) => {
    all[element.id] = createNode(element);

    return all;
  }, {});

  // Set parentId for all children elements in the next layer
  const allChildrenInNextHierarchy = atn_v2.reduce((all, element) => {
    return all.concat(
      NESTED_ELEMENT_KEYS.reduce((children, key) => {
        if (element.hasOwnProperty(key)) {
          return children.concat(
            element[key].map(child => ({ ...child, parentId: element.id }))
          );
        } else {
          return children;
        }
      }, [])
    );
  }, []);

  return {
    rootOrder: rootOrder,
    elements: {
      ...rootElements,
      ...loadATN(allChildrenInNextHierarchy).elements
    }
  };
}
