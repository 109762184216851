import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from "react-redux";
import createStore from "./atn_editor/createStore";
import * as serviceWorker from "./atn_editor/serviceWorker";

// import App from './App'; prev code
import App from './atn_editor/components/App'
// import reportWebVitals from './reportWebVitals'; prev code
const store = createStore();

ReactDOM.render(
 
  <React.StrictMode>
     <Provider store={store}>
    <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(); prev code
serviceWorker.unregister();
