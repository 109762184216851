import React from "react";
import { connect } from "react-redux";
import saveAs from "file-saver";

import BasicButton from "./BasicButton";
import FaIcon from "../main/FaIcon";

import { buildATN } from "../../helpers/atnRenderer";
import { selectElementsInOrder } from "../../selectors";

function download(title, elements, settings) {
  const atn = buildATN(elements, settings);

  const blob = new Blob([JSON.stringify(atn, null, 2)], {
    type: "text/plain;charset=utf-8"
  });

  saveAs(blob, `${title}_atn.json`);
}

const Downloadbutton = ({ title, className, elements, settings }) => (
  <BasicButton
    className={className}
    id="download-btn"
    size="L"
    onClick={() => download(title, elements, settings)}
  >
    <FaIcon left size="L" icon="download" /> Download
  </BasicButton>
);

export default connect(state => ({
  elements: selectElementsInOrder(state),
  settings: state.settings,
  title: state.documentHeader.title
}))(Downloadbutton);
