import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import IconButton from "../form/IconButton";
import IndexLabel from "../styled/IndexLabel";
import FaIcon from "../main/FaIcon";

import {
  colorOfStaticElement,
  nameOfStaticELement,
} from "../../helpers/elementHelper";

const Root = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: move;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 3px;
`;

class EditorElementHeader extends PureComponent<PropsType> {
  onRemoveElement = (e) => {
    e.stopPropagation(); // Prevent selecting the element we're about to remove

    this.props.onRemoveElement();
  };

  render() {
    const {
      className,
      color,
      idx,
      name,
      onCopyElement,
      staticElement,
      staticRootElement,
      allowCopy,
      elementId,
    } = this.props;

    return (
      <Root className={className}>
        <IndexLabel
          idx={idx}
          label={staticRootElement ? nameOfStaticELement(elementId) : name}
          color={staticRootElement ? colorOfStaticElement(elementId) : color}
        />
        {!staticElement && (
          <>
            {allowCopy && (
              <StyledIconButton
                data-testid="editor-element-copy-btn"
                tabIndex="-1"
                onClick={onCopyElement}
              >
                <FaIcon light center icon="copy" />
              </StyledIconButton>
            )}
            <StyledIconButton
              data-testid="editor-element-remove-btn"
              tabIndex="-1"
              onClick={this.onRemoveElement}
            >
              <FaIcon light center icon="trash" />
            </StyledIconButton>
          </>
        )}
      </Root>
    );
  }
}

export default EditorElementHeader;
