// @flow

import React, { Component } from "react";
import styled from "@emotion/styled";

import { LIST_ITEM } from "../../atnBase";

import ElementWithNesting from "./ElementWithNesting";

const Bubble = styled("div")`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333;
  margin: 0 12px 0 8px;
  flex-shrink: 0;
`;

class List extends Component {
  render() {
    return (
      <ElementWithNesting
        {...this.props}
        hasNoLabel={true}
        nestingKey="items"
        nestingElement={LIST_ITEM}
        nestingIndicator={<Bubble />}
        nestedContentKey="content"
        allowsFurtherNesting={false}
        labelPlaceholder="Titel der Stichpunktliste"
        optionPlaceholder="Stichpunkt"
      />
    );
  }
}

export default List;
