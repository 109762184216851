import React, { Component } from "react";
import styled from "@emotion/styled";
import { DragSource } from "react-dnd";

import { ICON_FOR_TYPE } from "../../atnBase";

import FaIcon from "./FaIcon";

const Root = styled("div")`
  background-color: ${({ disabled }) =>
    disabled ? "rgb(218, 218, 218)" : "white"};
  color: ${({ disabled }) => (disabled ? "grey" : "#333")};
  border-radius: 5px;
  margin: 2px 0;
  line-height: 1;
  font-weight: 700;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-left: ${({ color }) => "5px solid " + color};
  opacity: ${({ isDragging }) => (isDragging ? "0.5" : "1.0")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: opacity 0.1s;
  font-size: 16px;

  &:hover {
    opacity: ${({ disabled }) => (disabled ? "1" : "0.9")};
  }
`;

const StyledFaIcon = styled(FaIcon)`
  width: 25px !important;
  font-size: 14px;
`;

const cardSource = {
  canDrag(props) {
    const { disabled } = props;
    return !disabled;
  },

  beginDrag(props) {
    return {
      element: props.element,
    };
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class DrawerElement extends Component {
  render() {
    const {
      isDragging,
      connectDragSource,
      element,
      className,
      onAddElement,
      disabled,
    } = this.props;

    const type = element.static ? element.staticType : element.defaultAtn.type;

    return (
      <Root
        color={element.color}
        isDragging={isDragging}
        className={className}
        onClick={onAddElement}
        disabled={disabled}
        ref={(instance) => connectDragSource(instance)}
        id={`drawer-element-${type.replace(/[A-Z]/g, "-$&").toLowerCase()}`}
      >
        <StyledFaIcon icon={ICON_FOR_TYPE[type]} /> {element.name}
      </Root>
    );
  }
}

export default DragSource("EDITOR_ELEMENT", cardSource, collect)(DrawerElement);
