// @flow

import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Hotkeys from "react-hot-keys";

import { setView } from "../../actions";

import ToggleButton from "../form/ToggleButton";

const Label = styled("p")`
  margin: 0 10px 0 0;
  font-size: 14px;
  text-transform: uppercase;
  color: #666;
`;

export default connect(
  state => ({
    condensed: state.view.condensed
  }),
  dispatch => ({
    setCondensed: value => dispatch(setView("condensed", value))
  })
)(({ className, condensed, setCondensed }) => (
  <div className={className}>
    <Hotkeys keyName="alt+k" onKeyUp={() => setCondensed(!condensed)} />
    <Label>Kompakt</Label>
    <ToggleButton value={condensed} onChange={value => setCondensed(value)} />
  </div>
));

// They were beautiful.
// But people didn't like playful
// Rest in comments

// <IconButton active={condensed} size="L" onClick={() => setCondensed(true)}>
//   😌
// </IconButton>
// <IconButton
//   active={!condensed}
//   size="L"
//   onClick={() => setCondensed(false)}
// >
//   🤔
// </IconButton>
