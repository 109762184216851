// @flow

import * as React from "react";
import styled from "@emotion/styled";

const Root = styled("div")`
  padding: 20px 0 5px;
`;

const Label = styled("p")`
  margin: 2px 2px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #666;
`;

type PropsType = {
  label: string,
  children?: React.Node
};

const StylePicker = (props: PropsType) => {
  return (
    <Root>
      <Label>{props.label}</Label>
      {props.children}
    </Root>
  );
};

export default StylePicker;
