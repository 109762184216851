// @flow

import { batchActions } from "redux-batched-actions";

import type {
  ElementsType,
  ElementType,
  RootOrderType
} from "./reducers/elementsReducer";

import type { ATNType } from "./types";

import { loadATN } from "./helpers/atnLoader";
import { putDocumentTemplate, getDocumentTemplate } from "./api";

export function addElement(element: ElementType) {
  return {
    type: "ELEMENT_ADD",
    payload: {
      element
    }
  };
}

export function addElementAndRegisterChild(
  parentId: string,
  element: ElementType,
  nestingKey: string
) {
  return {
    type: "ELEMENT_ADD_AND_REGISTER_CHILD",
    payload: { parentId, element, nestingKey }
  };
}

export function moveElement(
  sourceId: string,
  targetId: number,
  below: boolean,
  nestedInKey?: string
) {
  return {
    type: "ELEMENT_MOVE",
    payload: {
      sourceId,
      targetId,
      below,
      nestingKey: nestedInKey
    }
  };
}

export function removeElement(id: string) {
  return {
    type: "ELEMENT_REMOVE",
    payload: {
      id
    }
  };
}
export function copyElement(id: string, nestedInKey?: string) {
  return {
    type: "ELEMENT_COPY",
    payload: {
      id,
      nestingKey: nestedInKey
    }
  };
}

export function setElementAttr(id: string, attr: string, value: any) {
  return {
    type: "ELEMENT_SET_ATTR",
    payload: {
      id,
      attr,
      value
    }
  };
}

export function removeElementAttr(id: string, attr: string) {
  return {
    type: "ELEMENT_REMOVE_ATTR",
    payload: {
      id,
      attr
    }
  };
}

export function setElementStyleAttr(id: string, attr: string, value: any) {
  return {
    type: "ELEMENT_SET_STYLE_ATTR",
    payload: {
      id,
      attr,
      value
    }
  };
}

export function setSetting(attr: string, value: any) {
  return {
    type: "SETTINGS_SET",
    payload: {
      [attr]: value
    }
  };
}

export function setView(attr: string, value: any) {
  return {
    type: "VIEW_SET",
    payload: {
      [attr]: value
    }
  };
}

export function setActiveElement(id: ?string, nestedInKey?: string) {
  return {
    type: "ACTIVE_ELEMENT_SET",
    payload: {
      id,
      nestedInKey
    }
  };
}

export function addLanguage(lang: string) {
  return {
    type: "SETTINGS_ADD_LANGUAGE",
    payload: { lang }
  };
}
export function removeLanguage(lang: string) {
  return {
    type: "SETTINGS_REMOVE_LANGUAGE",
    payload: { lang }
  };
}

export function registerChild(
  parentId: string,
  childId: string,
  nestingKey: string
) {
  return {
    type: "ELEMENT_REGISTER_CHILD",
    payload: { parentId, childId, nestingKey }
  };
}

export function addChild(
  parentId: string,
  child: ElementType,
  nestingKey: string
) {
  return {
    type: "ELEMENT_ADD_CHILD",
    payload: { parentId, child, nestingKey }
  };
}

export function toggleFeature(feature: string) {
  return {
    type: "FEATURE_TOGGLE",
    payload: { feature }
  };
}

export function replaceElements(
  elements: ElementsType,
  rootOrder: RootOrderType
) {
  return {
    type: "ELEMENTS_REPLACE",
    payload: { elements, rootOrder }
  };
}

export function addStaticElement(
  elements: ElementsType,
  rootOrder: RootOrderType
) {
  return {
    type: "STATIC_ELEMENT_ADD",
    payload: { elements, rootOrder },
  };
}

export function addElementAndMove(
  element: ElementType,
  targetId: string,
  below: boolean,
  nestedInKey: string
) {
  return {
    type: "ELEMENT_ADD_AND_MOVE",
    payload: {
      element,
      targetId,
      below,
      nestingKey: nestedInKey
    }
  };
}

const loadDocStart = () => ({ type: "DOC_LOAD_START" });
const loadDocSuccess = () => ({ type: "DOC_LOAD_SUCCESS" });
const loadDocError = () => ({ type: "DOC_LOAD_ERROR" });

export const loadDocumentTemplate = () => dispatch => {
  dispatch(loadDocStart());
  return getDocumentTemplate()
    .then(res => {
      const {
        csrfToken,
        result: {
          data: {
            document_template: {
              atn_v2,
              title,
              languages,
              template_category_id
            }
          }
        }
      } = res;

      const { practice } = res.result.data;
      const { elements, rootOrder } = loadATN(
        atn_v2 && atn_v2.length > 0 ? JSON.parse(atn_v2) : []
      );

      // Batch actions to not re-render between them
      dispatch(
        batchActions([
          setDocumentHeader("title", title),
          setDocumentHeader("category", template_category_id),
          setMeta("csrfToken", csrfToken),
          replaceElements(elements, rootOrder),
          setPractice(practice)
        ])
      );

      // Batch actions to not re-render between them
      if (languages && languages.length > 0) {
        const langs = JSON.parse(languages);

        dispatch(
          batchActions([
            setSetting("languages", langs),
            setSetting("activeLang", langs[0])
          ])
        );
      }

      return dispatch(loadDocSuccess());
    })
    .catch(e => {
      console.error(e);
      alert(
        "Es ist ein Netzwerkfehler aufgetreten. Bitte noch einmal versuchen."
      );
      return dispatch(loadDocError());
    });
};

const saveDocStart = () => ({ type: "DOC_SAVE_START" });
const saveDocSuccess = () => ({ type: "DOC_SAVE_SUCCESS" });
const saveDocError = () => ({ type: "DOC_SAVE_ERROR" });

export const saveDocumentTemplate = (
  title: string,
  atn: ATNType,
  languages: string[]
) => dispatch => {
  dispatch(saveDocStart());

  return putDocumentTemplate(title, atn, languages)
    .then(res => {
      return dispatch(saveDocSuccess());
    })
    .catch(e => {
      return dispatch(saveDocError());
    });
};

export function setDocumentHeader(attr: string, value: any) {
  return {
    type: "DOCUMENT_HEADER_SET",
    payload: { [attr]: value }
  };
}

export function setPreview(value: boolean) {
  return {
    type: "PREVIEW_SET",
    payload: { show: value }
  };
}
export function setMeta(attr: string, value: any) {
  return {
    type: "META_SET",
    payload: { [attr]: value }
  };
}

export function setPractice(practice) {
  return {
    type: "PRACTICE_SET",
    payload: {
      ...practice
    }
  };
}
//
