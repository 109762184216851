// @flow

import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import PreviewCheckbox from "./PreviewCheckbox";
import PreviewRadioButton from "./PreviewRadioButton";

import type { ATNValueType } from "../../types";

const HeadingElement = styled("div")`
  text-align: ${({ align }) => align};

  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    margin: 0;
    font-size: 20px;
  }
  h4 {
    margin: 0;
    font-size: 18px;
  }
  h5 {
    margin: 0;
    font-size: 14px;
  }
`;
const Heading = ({ activeLang, element: { content, style } }) => {
  const { headingSize, ...rest } = style;

  return (
    <HeadingElement>
      {React.createElement("h" + headingSize, rest, content[activeLang])}
    </HeadingElement>
  );
};

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Col = styled("div")`
  text-align: left;
  flex-grow: 1;
  flex-base: 0;
  padding: 0 10px;
  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
`;
const Group = ({ activeLang, element }) => (
  <Row>
    {element.children.map((child) => (
      <Col key={child.id}>
        <PreviewElement activeLang={activeLang} element={child} />
      </Col>
    ))}
  </Row>
);

const Ol = styled("ol")`
  font-size: 18px;
  margin: 20px 0;
  padding-inline-start: 20px;
`;
const Ul = styled("ul")`
  list-style-type: disc;
  font-size: 18px;
  margin: 20px 0;
  padding-inline-start: 20px;
`;
const Li = styled("li")`
  margin: 5px 0;
`;
const List = ({ activeLang, element }) =>
  element.style.order === "ordered" ? (
    <Ol>
      {element.items.map((item) => (
        <Li key={item.id}>{item.content[activeLang]}</Li>
      ))}
    </Ol>
  ) : (
    <Ul>
      {element.items.map((item) => (
        <Li key={item.id}>{item.content[activeLang]}</Li>
      ))}
    </Ul>
  );

const Text = ({ activeLang, element }) => (
  <div dangerouslySetInnerHTML={{ __html: element.content[activeLang] }} />
);

const Paragraph = ({ activeLang, element }) => (
  <p dangerouslySetInnerHTML={{ __html: element.content[activeLang] }} />
);

const StyledHr = styled("hr")`
  margin: 50px 0;
  background-color: #ccc;
  width: ${({ line }) => (line === "short" ? "50%" : "100%")};
  margin-left: ${({ line }) => (line === "short" ? "auto" : "initial")};
  margin-right: ${({ line }) => (line === "short" ? "auto" : "initial")};
`;
const StyledDots = styled("div")`
  margin: 50px auto;
  text-align: center;

  &:before {
    color: #ccc;
    content: "• • •";
    font-family: serif;
    font-size: 20px;
    letter-spacing: 1.5em;
    padding-left: 2em;
  }
`;
const Separator = ({ element }) =>
  element.style && element.style.line === "dots" ? (
    <StyledDots />
  ) : (
    <StyledHr {...element.style} />
  );

const DivWithSpace = styled("div")`
  width: 100%;
  height: ${({ size }) =>
    size === "l" ? "100px" : size === "m" ? "50px" : "20px"};
`;
const Spacer = ({ element }) => <DivWithSpace {...element.style} />;

const ImgWrap = styled("div")`
  text-align: ${({ align }) => align};
`;
const Img = styled("img")`
  width: ${({ size }) => {
    return size === "s" ? "33%" : size === "m" ? "50%" : "100%";
  }};
`;

const Image = ({ element }) => {
  return (
    <ImgWrap {...element.style}>
      <Img {...element.style} alt={element.source} src={element.source} />
    </ImgWrap>
  );
};

const Container = styled("div")`
  padding: 5px 0 10px;
`;
const Input = styled("input")`
  color: #024761;
  outline-width: 0;
  padding: 12px 30px 12px 8px;
  font-size: 20px;
  border-radius: 5px;
  border: ${({ noBorder }) => (noBorder ? "none" : "1px solid #024761")};
  width: ${({ size }) =>
    size === "l" ? "100%" : size === "m" ? "50%" : "33%"};
  box-sizing: border-box;

  &::placeholder {
    color: #aaa;
  }
`;
const TextInput = ({ activeLang, element }) => (
  <Container>
    {element.label[activeLang] && <Label>{element.label[activeLang]}</Label>}
    <Input
      placeholder={element.placeholder[activeLang]}
      type="text"
      {...element.style}
    />
  </Container>
);

const TextAreaInput = styled("textarea")`
  color: #024761;
  outline-width: 0;
  border-radius: 5px;
  border: ${({ noBorder }) => (noBorder ? "none" : "1px solid #024761")};
  width: ${({ size }) =>
    size === "l" ? "100%" : size === "m" ? "50%" : "33%"};
  padding: 8px 10px;
  font-size: 20px;

  &::placeholder {
    color: #aaa;
  }
`;
const TextArea = ({ activeLang, element }) => (
  <Container>
    {element.label[activeLang] && <Label>{element.label[activeLang]}</Label>}
    <TextAreaInput
      placeholder={element.placeholder[activeLang]}
      rows="4"
      {...element.style}
    />
  </Container>
);

const Options = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Option = styled("div")`
  font-size: 20px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  padding: 7px 10px;
  margin-right: 12px;
  margin-bottom: 12px;
  background-color: #f9f9f9;
`;
const StyledPreviewRadioButton = styled(PreviewRadioButton)`
  flex-shrink: 0;
`;
const StyledPreviewCheckbox = styled(PreviewCheckbox)`
  flex-shrink: 0;
`;
const Label = styled("h3")`
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 10px !important;
`;
const Children = styled("div")`
  padding-left: 20px;
  border-left: 8px solid #f9f9f9;
`;

class SelectOne extends React.Component {
  state = {};

  render() {
    const { activeLang, element } = this.props;

    return (
      <Container>
        <Label>{element.label[activeLang]}</Label>
        {element.style.optionsArrangement === "horizontal" && (
          <Options>
            {element.options.map((option, i) => {
              return (
                <Option
                  key={option.id}
                  onClick={() => this.setState({ selected: i })}
                >
                  <StyledPreviewRadioButton
                    checked={this.state.selected === i}
                  />
                  <span>{option.label[activeLang]}</span>
                </Option>
              );
            })}
          </Options>
        )}
        {element.options.map((option, i) => (
          <div key={option.id}>
            {element.style.optionsArrangement === "vertical" && (
              <Option
                key={option.id}
                onClick={() => this.setState({ selected: i })}
              >
                <StyledPreviewRadioButton checked={this.state.selected === i} />
                <span>{option.label[activeLang]}</span>
              </Option>
            )}
            {this.state.selected === i && (
              <Children>
                {option.children.map((child) => (
                  <PreviewElement
                    activeLang={activeLang}
                    element={child}
                    key={child.id}
                  />
                ))}
              </Children>
            )}
          </div>
        ))}
      </Container>
    );
  }
}

class SelectMultiple extends React.Component {
  state = {};

  render() {
    const { activeLang, element } = this.props;

    return (
      <Container>
        <Label>{element.label[activeLang]}</Label>
        {element.options.map((option, i) => {
          return (
            <div key={option.id}>
              <Option onClick={() => this.setState({ [i]: !this.state[i] })}>
                <StyledPreviewCheckbox checked={!!this.state[i]} />
                <span>{option.label[activeLang]}</span>
              </Option>
              {this.state[i] &&
                option.children.map((child) => (
                  <PreviewElement
                    activeLang={activeLang}
                    element={child}
                    key={child.id}
                  />
                ))}
            </div>
          );
        })}
      </Container>
    );
  }
}

const Sign = styled("div")`
  width: 300px;
  margin: 35px 0;
  padding-bottom: 10px;
`;
const SignField = styled("div")`
  cursor: pointer;
  border: 4px dashed #999;
  padding: 20px;
  text-align: left;
`;
const Italic = styled("p")`
  font-style: italic;
  font-size: 18px;
  margin: 10px 20px;
`;
const SignTitle = styled("p")`
  font-size: 20px;
  margin-bottom: 10px;
`;
const SignInfoLabel = styled("p")`
  margin-top: 10px;
  font-size: 16px;
  color: #666;
`;
const Signature = ({ activeLang, element }) => (
  <Sign>
    <SignField>
      <SignTitle>Unterschrift</SignTitle>
      <SignInfoLabel>{element.infoLabel[activeLang]}</SignInfoLabel>
    </SignField>
    <Italic>{element.caption[activeLang]}</Italic>
  </Sign>
);

const VideoWrap = styled("div")`
  text-align: ${({ align }) => align};
`;
const Video = ({ element }) => (
  <VideoWrap {...element.style}>
    <video
      controls="controls"
      poster={element.placeholderSource}
      src={element.source}
      style={{ maxWidth: 700 }}
    />
  </VideoWrap>
);

type PropsType = {
  activeLang: string,
  element: ATNValueType,
};

class PreviewElement extends PureComponent<PropsType> {
  render() {
    const { activeLang, element } = this.props;

    switch (element.type) {
      case "heading":
        return <Heading activeLang={activeLang} element={element} />;
      case "group":
        return <Group activeLang={activeLang} element={element} />;
      case "list":
        return <List activeLang={activeLang} element={element} />;
      case "text":
        return <Text activeLang={activeLang} element={element} />;
      case "paragraph":
        return <Paragraph activeLang={activeLang} element={element} />;
      case "separator":
        return <Separator element={element} />;
      case "spacer":
        return <Spacer element={element} />;
      case "image":
        return <Image element={element} />;
      case "video":
        return <Video element={element} />;
      case "textInput":
        return <TextInput activeLang={activeLang} element={element} />;
      case "textAreaInput":
        return <TextArea activeLang={activeLang} element={element} />;
      case "yesNo":
        return (
          <SelectOne
            activeLang={activeLang}
            element={{
              ...element,
              style: { ...element.style, optionsArrangement: "horizontal" },
            }}
          />
        );
      case "signature":
        return <Signature activeLang={activeLang} element={element} />;
      case "selectOne":
        return <SelectOne activeLang={activeLang} element={element} />;
      case "selectMultiple":
        return <SelectMultiple activeLang={activeLang} element={element} />;
      default:
        return <p style={{ backgroundColor: "red" }}>Not a match</p>;
    }
  }
}

export default PreviewElement;
