// @flow

import axios from "axios";
import { DirectUpload } from "@rails/activestorage";

const DOCUMENT_TEMPLATE_ID = window.location.pathname.split("/").reverse()[1] == '' ? 0 : window.location.pathname.split("/").reverse()[1];

// New Local Url's
// const BASE_URL = "https://localhost:44310";
// export const RETURN_URL = "http://localhost:8000";

// Client Server
const BASE_URL = "https://api.altona.app";
export const RETURN_URL = "https://dashboard.altona.app";


function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2)
    return parts
      .pop()
      .split(";")
      .shift();

  return "";
}

export function getDocumentTemplate() {
  return axios({
    method: "Get",
    url: `${BASE_URL}/atn_editor_api/v1/document_templates/${DOCUMENT_TEMPLATE_ID}`,
    // { withCredentials: true }

  }).then(result => {
    return {
      result,
      csrfToken: decodeURIComponent(getCookie("CSRF-TOKEN"))
    };
  });
}

export function uploadFile(file) {
  debugger
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result)
    };
    fr.onerror = reject;
    fr.readAsDataURL(file)
  });
}

function putDocumentTemplateWithFormData(formData) {
  return axios({
    method: "Post",
    // url: `${BASE_URL}/atn_editor_api/v1/document_templates/`,
    url: `${BASE_URL}/atn_editor_api/v1/document_templates/${DOCUMENT_TEMPLATE_ID}`,
    data: formData,
    // headers: {
    //   "Content-Type": "multipart/form-data",
    //   "X-CSRF-Token": decodeURIComponent(getCookie("CSRF-TOKEN"))
    // },
    // withCredentials: true
  });
}

export function putDocumentTemplate(title, atn, languages) {
  const bodyFormData = new FormData();

  bodyFormData.append("document_template[title]", title);
  bodyFormData.append("document_template[atn_v2]", JSON.stringify(atn));
  bodyFormData.append("document_template[languages]", JSON.stringify(languages));
  bodyFormData.append("document_template[profileId]", JSON.stringify(languages));

  return putDocumentTemplateWithFormData(bodyFormData);
}

export function redirectToDocumentTemplate() {
  window.location.href = `${BASE_URL}/internal/document_templates/${DOCUMENT_TEMPLATE_ID}`;
}

export function getSignedUrl(id, prop) {
  debugger
  const data = {
    "Logo": id,
  };
  return axios({
    method: 'Post',
    url: BASE_URL + '/atn_editor_api/v1/document_templates/' + DOCUMENT_TEMPLATE_ID + '/medias',
    data: data
  }).then(response => {
    debugger
    prop.props.element.atn.source = BASE_URL + '/' + response.data;
    prop.onChange(BASE_URL + '/' + response.data)
    return response;
  });
  // return axios(
  //   `${BASE_URL}/atn_editor_api/v1/document_templates/${DOCUMENT_TEMPLATE_ID}/medias`,
  //   { withCredentials: true ,data:data}
  // );
}
