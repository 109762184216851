// @flow

export type StateType = {
  languages: string[],
  activeLang: string
};

export const initialState: StateType = {
  languages: ["de"], // The languages selected from all possible languages
  activeLang: "de" // Can only be one of the ones in "languages"
};

function reducer(state: StateType = initialState, action: Object): StateType {
  switch (action.type) {
    case "SETTINGS_SET":
      return { ...state, ...action.payload };
    case "SETTINGS_ADD_LANGUAGE":
      return { ...state, languages: [...state.languages, action.payload.lang] };
    case "SETTINGS_REMOVE_LANGUAGE":
      if (state.languages.length <= 1) return state;

      const idx = state.languages.indexOf(action.payload.lang);
      const newLanguages = [
        ...state.languages.slice(0, idx),
        ...state.languages.slice(idx + 1)
      ];

      return {
        ...state,
        activeLang: newLanguages.includes(state.activeLang)
          ? state.activeLang
          : newLanguages[0],
        languages: newLanguages
      };
    default:
      return state;
  }
}

export default reducer;
