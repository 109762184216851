// @flow

import React from "react";

import RadioButton from "../form/RadioButton";
import StylePicker from "./StylePicker";

type PropsType = {
  label: string,
  value: { size: string },
  onChange: (string, string) => void
};

const SizePicker = (props: PropsType) => {
  return (
    <StylePicker label={props.label}>
      <RadioButton
        value={props.value.size}
        options={
          props.options || [
            { label: "S", value: "s" },
            { label: "M", value: "m" },
            { label: "L", value: "l" }
          ]
        }
        onChange={value => props.onChange("size", value)}
      />
    </StylePicker>
  );
};

export default SizePicker;
