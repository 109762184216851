// @flow

import * as React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { DragSource } from "react-dnd";
import isEqual from "react-fast-compare";

import type { ElementType } from "../../reducers/elementsReducer";
import type { StateType as SettingsType } from "../../reducers/settingsReducer";
import {
  isStaticElement,
  isStaticRootElement,
} from "../../helpers/elementHelper";
import { loadATN } from "../../helpers/atnLoader";

import {
  addElementAndMove,
  copyElement,
  moveElement,
  removeElement,
  removeElementAttr,
  setActiveElement,
  setElementAttr,
  setView,
  addStaticElement,
} from "../../actions";

import {
  selectElement,
  selectElementDisplayIndex,
  selectElementCondensed,
} from "../../selectors";

import Heading from "../elements/Heading";
import Separator from "../elements/Separator";
import Spacer from "../elements/Spacer";
import Paragraph from "../elements/Paragraph";
import Text from "../elements/Text";
import Image from "../elements/Image";
import Signature from "../elements/Signature";
import TextInput from "../elements/TextInput";
import TextareaInput from "../elements/TextareaInput";
import SelectOne from "../elements/SelectOne";
import YesNo from "../elements/YesNo";
import List from "../elements/List";
import Video from "../elements/Video";
import SelectMultiple from "../elements/SelectMultiple";
import Group from "../elements/Group";
import CameraInput from "../elements/CameraInput";

import EditorElementHeader from "./EditorElementHeader";
import DropIndicator from "./DropIndicator";
import ContentPreview from "./ContentPreview";

const Root = styled("div")`
  margin: 0;
  padding: 0px;
  transition: box-shadow 0.1s;

  box-shadow: ${({ isActive }) =>
    isActive ? "inset 0 0 0 1px #015270" : "none"};
  position: relative;
`;

const Header = styled("div")`
  padding: 0px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const StyledEditorElementHeader = styled(EditorElementHeader)`
  margin-bottom: ${({ isCondensed }) => (isCondensed ? "0" : "5px")};
`;

const Content = styled("div")`
  padding: 0 14px;
`;

const cardSource = {
  canDrag(props, monitor) {
    const draggable = !isStaticElement(props) || isStaticRootElement(props);
    if (!draggable) return false;
    return !monitor.isDragging();
  },

  beginDrag(props, monitor, component) {
    return {
      id: props.element.id,
      element: props.element,
    };
  },
};

function collectDrag(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

type PropsType = {
  idx: number,
  className?: string,
  isCondensed: boolean,
  isActive: boolean,
  onSetActiveElement: () => void,
  onSetUncondensedElement: () => void,
  onMoveElement: (string, boolean) => void,
  onCopyElement: () => void,
  onRemoveElement: () => void,
  onAddElementAndMove: (ElementType, boolean) => void,
  onMount: (React.Ref<"div">) => void,
  element: ElementType,
  elementId: string,
  onChange: (attr: string, value: any) => void,
  settings: SettingsType,
  connectDragSource: Function,
  onAddStaticElementAndMove: (Object, boolean) => void,
  preventDnd?: boolean,
  isOver: boolean,
  sourceId: ?string,
};

class EditorElement extends React.Component<PropsType> {
  root: React.Ref<"div">;

  constructor(props) {
    super(props);

    this.root = React.createRef();
  }

  componentDidMount() {
    const {
      onMountNotify,
      activeOnMount,
      onScrollToMe,
      onSetActiveElement,
    } = this.props;

    onMountNotify(this.props.element.id);

    if (activeOnMount) {
      if (onScrollToMe) onScrollToMe(this.root);

      onSetActiveElement();
    }
  }

  onDrop = (sourceId, element, dropBelow) => {
    const { onAddStaticElementAndMove } = this.props;

    if (!sourceId) {
      // It's a new element, dragged in from the drawer,
      // so it doesn't have an id yet
      if (element.static) {
        return onAddStaticElementAndMove(element, dropBelow);
      }
      this.props.onAddElementAndMove(element, dropBelow);
    } else {
      // It's an existing element moved around
      this.props.onMoveElement(sourceId, dropBelow);
    }
  };

  onDropAbove = (sourceId, element) => {
    this.onDrop(sourceId, element, false);
  };
  onDropBelow = (sourceId, element) => {
    this.onDrop(sourceId, element, true);
  };

  selectElement = (e) => {
    // Prevent selecting group when inner element clicked
    e.stopPropagation();

    this.props.onSetActiveElement();

    if (!this.props.element.parentId) {
      // Set a clicked root element un-condensed
      this.props.onSetUncondensedElement();
    }
  };

  render() {
    const {
      className,
      isCondensed,
      idx,
      isActive,
      onRemoveElement,
      onCopyElement,
      connectDragSource,
      preventDnd,
      element: {
        name,
        color,
        atn: { type },
      },
      elementId,
    } = this.props;

    const staticElement = isStaticElement(this.props);
    const staticRootElement = isStaticRootElement(this.props);
    const editable = !staticElement || staticRootElement;
    const extendedProps = { ...this.props, staticElement };

    return (
      <Root
        ref={this.root}
        className={className}
        onClick={(e) => editable && this.selectElement(e)}
        isActive={isActive}
        data-testid="editor-element"
      >
        {editable && <DropIndicator onDrop={this.onDropAbove} />}
        <Header
          ref={(instance) => {
            if (!preventDnd) {
              connectDragSource(instance);
            }
          }}
          isActive={isActive}
        >
          <StyledEditorElementHeader
            isCondensed={isCondensed}
            color={color}
            name={name}
            idx={idx}
            onRemoveElement={onRemoveElement}
            onCopyElement={onCopyElement}
            staticElement={!editable}
            staticRootElement={staticRootElement}
            allowCopy={!staticElement}
            elementId={elementId}
          />
          {isCondensed && <ContentPreview element={this.props.element} />}
        </Header>
        {!isCondensed && (
          <Content>
            {type === "heading" && <Heading {...extendedProps} />}
            {type === "separator" && <Separator {...extendedProps} />}
            {type === "spacer" && <Spacer {...extendedProps} />}
            {type === "text" && <Text {...extendedProps} />}
            {type === "paragraph" && <Paragraph {...extendedProps} />}
            {type === "image" && <Image {...extendedProps} />}
            {type === "video" && <Video {...extendedProps} />}
            {type === "signature" && <Signature {...extendedProps} />}
            {type === "textInput" && <TextInput {...extendedProps} />}
            {type === "textAreaInput" && <TextareaInput {...extendedProps} />}
            {type === "list" && <List {...extendedProps} />}
            {type === "group" && <Group {...extendedProps} />}
            {type === "selectOne" && <SelectOne {...extendedProps} />}
            {type === "yesNo" && <YesNo {...extendedProps} />}
            {type === "selectMultiple" && <SelectMultiple {...extendedProps} />}
            {type === "cameraInput" && <CameraInput {...extendedProps} />}
          </Content>
        )}
        {editable && <DropIndicator below onDrop={this.onDropBelow} />}
      </Root>
    );
  }
}

const makeMapStateToProps = (_, ownProps) => (state) => ({
  idx: selectElementDisplayIndex(state, ownProps),
  element: selectElement(state, ownProps),
  isActive: state.activeElement.id === ownProps.elementId,
  isCondensed: selectElementCondensed(state, ownProps),
  settings: state.settings,
  multiLanguage: state.feature.multiLanguage,
});

const makeMapDispatchToProps = (_, ownProps) => (dispatch) => ({
  onSetActiveElement: () =>
    dispatch(setActiveElement(ownProps.elementId, ownProps.nestedInKey)),
  onSetUncondensedElement: () =>
    dispatch(setView("unCondensedElementId", ownProps.elementId)),
  onRemoveElement: () => dispatch(removeElement(ownProps.elementId)),
  onCopyElement: () =>
    dispatch(copyElement(ownProps.elementId, ownProps.nestedInKey)),
  onAddElementAndMove: (element, below) => {
    const targetId = ownProps.elementId;

    dispatch(addElementAndMove(element, targetId, below, ownProps.nestedInKey));
  },
  onMoveElement: (sourceId, below) => {
    const targetId = ownProps.elementId;

    if (sourceId !== targetId) {
      dispatch(moveElement(sourceId, targetId, below, ownProps.nestedInKey));
    }
  },
  onChange: (attr, value) =>
    dispatch(setElementAttr(ownProps.elementId, attr, value)),
  onRemoveElementAttribute: (attr) =>
    dispatch(removeElementAttr(ownProps.elementId, attr)),
  onAddStaticElementAndMove: (element, below) => {
    const { elements, rootOrder } = loadATN(element.atn);
    const targetId = ownProps.elementId;
    const sourceId = rootOrder[0];
    dispatch(addStaticElement(elements, rootOrder));
    if (sourceId !== targetId) {
      dispatch(moveElement(sourceId, targetId, below, ownProps.nestedInKey));
    }
  },
});

export default connect(
  makeMapStateToProps,
  makeMapDispatchToProps,
  null,
  {
    areStatePropsEqual: (prev, next) => {
      return isEqual(prev, next);
    },
  }
)(DragSource("EDITOR_ELEMENT", cardSource, collectDrag)(EditorElement));
