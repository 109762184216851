import React from "react";
import styled from "@emotion/styled";

import LinePicker from "../style-form/LinePicker";

const Root = styled("div")`
  margin: 0;
`;

const SelectOne = props => {
  return (
    <Root>
      <LinePicker
        label="Linien-Typ"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
    </Root>
  );
};

export default SelectOne;
