import React, { PureComponent } from "react";
import styled from "@emotion/styled";

import BackButton from "../form/BackButton";
import FaIcon from "../main/FaIcon";

const Root = styled("div")`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #015270;
  padding: 6px 20px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  color: white;
`;

const Light = styled("h1")`
  margin: 0;
  font-weight: 700;
  letter-spacing: 1px;
  padding-left: 0px;
  font-size: 20px;
  text-transform: uppercase;
`;

const Headline = styled("h1")`
  margin: 0;
  font-weight: 300;
  font-size: 20px;
  text-transform: uppercase;
  margin-right: 12px;
`;

const Logo = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const StyledIcon = styled(FaIcon)`
  color: #ffffff;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 20px;
  top: 0;
`;

type PropsType = {
  backPath: string
};

const EDITOR_MANUAL_LINK = "https://hilfe.athenaapp.de/docs/athena-editor";

class Header extends PureComponent<PropsType> {
  render() {
    return (
      <Root>
        <StyledBackButton backPath={this.props.backPath} />
        <Logo>
          <Headline>Athena</Headline>
          <Light>Editor</Light>
        </Logo>
        <a
          href={EDITOR_MANUAL_LINK}
          target="_blank"
          rel="noopener"
          title="Anleitung"
        >
          <StyledIcon right size="L" icon="question-circle" />
        </a>
      </Root>
    );
  }
}

export default Header;
