export default {
  de: {
    dsWinFieldName: {
      anrede: "Anrede Patient",
      titel: "Titel Patient",
      vorname: "Vorname Patient",
      nachname: "Nachname Patient",
      ort: "Ort Patient",
      land: "Land Patient",
      telefon: "Telefon privat Patient",
      telefon2: "Telefon 2 Patient",
      mobil: "Telefon mobil Patient",
      email: "Email Patient",
      strasse: "Straße Patient",
      strassenzusatz: "Straßenzusatz Patient",
      geburtsdatum: "Geburtsdatum Patient",
      versicherter_anrede: "Anrede Versicherter",
      versicherter_titel: "Titel Versicherter",
      versicherter_vorname: "Vorname Versicherter",
      versicherter_nachname: "Nachname Versicherter",
      versicherter_ort: "Ort Versicherter",
      versicherter_land: "Land Versicherter",
      versicherter_telefon: "Telefon Versicherter",
      versicherter_strasse: "Straße Versicherter",
      versicherter_strassenzusatz: "Straßenzusatz Versicherter",
      versicherter_geburtsdatum: "Geburtsdatum Versicherter"
    },
    dsWinFieldNameSelect: {
      patientDataChange: "Stammdatenänderung im DS-Win"
    }
  }
};
