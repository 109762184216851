const DEFAULT_LOCALE = "de";

function countToKey(count) {
  if (count === undefined) {
    return [];
  }

  switch (count) {
    case 0:
      return ["zero"];
    case 1:
      return ["one"];
    default:
      return ["other"];
  }
}

function keyToString(key, params) {
  let resultKey = key;
  if (Object.prototype.toString.call(key) === "[object Array]") {
    if (params.count) resultKey.concat(countToKey(params.count));
    return resultKey.join(".");
  }
  if (typeof params.count === "number")
    resultKey += `.${countToKey(params.count)[0]}`;
  return resultKey;
}

function keyToArray(key) {
  if (typeof key === "string") {
    return key.split(".");
  }

  return key;
}

function capitalizeWord(word) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

function titleize(sentence) {
  const result = [];
  sentence.split(" ").forEach(word => {
    result.push(capitalizeWord(word));
  });

  return result.join(" ");
}

function getCurrentLocale() {
  if (!window.locale) {
    return DEFAULT_LOCALE;
  }
  return window.locale;
}

function errorMessage(key, params) {
  if (window.ENV && ["development", "test"].includes(window.ENV.RAILS_ENV)) {
    const message = `Translation "${keyToString(key, params)}" is missing.`;
    if (window.ENV.RAILS_ENV === "test") {
      throw message;
    }
    console.error(message); // eslint-disable-line no-console
    return message;
  }

  return titleize(keyToArray(key).pop());
}

function interpolate(translation, params) {
  if (!translation || !params || Object.keys(params).length === 0) {
    return translation;
  }

  return translation.replace(/{([^{}]*)}/g, (match, key) => {
    const val = params[key];
    return typeof val === "string" || typeof val === "number" ? val : match;
  });
}

function findTranslation(key, params, locale, locales) {
  const path = [locale].concat(keyToArray(key), countToKey(params.count));
  const translation = path.reduce((prev, current) => {
    return prev ? prev[current] : undefined;
  // }, locales || self);
  }, locales || window.self);

  return interpolate(translation, params);
}

export function translate(
  key,
  params = {},
  locale = getCurrentLocale(),
  locales = undefined
) {
  const translation = findTranslation(key, params, locale, locales);

  return translation ? translation : errorMessage(key, params);
}
