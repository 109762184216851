// @flow

import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";

import { setDocumentHeader } from "../../actions";

import Input from "../form/Input";
import SaveButton from "../form/SaveButton";
import PreviewButton from "../form/PreviewButton";
import DownloadButton from "../form/DownloadButton";
import FaIcon from "../main/FaIcon";

import LanguageSelect from "./LanguageSelect";
import ViewOptions from "./ViewOptions";

const Root = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 10px 4px 20px;
  background-color: white;
  width: 100%;
  color: #333;
  border-bottom: 1px solid #ccc;
`;

const StyledInput = styled(Input)`
  font-style: ${({ italic }) => (italic ? "italic" : "initial")};
`;

const StyledSaveButton = styled(SaveButton)`
  flex-shrink: 0;
`;
const StyledPreviewButton = styled(PreviewButton)`
  margin-right: 10px;
  flex-shrink: 0;
`;

const StyledDownloadButton = styled(DownloadButton)`
  margin-right: 10px;
  flex-shrink: 0;
`;

const StyledViewOptions = styled(ViewOptions)`
  margin-right: 10px;
  flex-shrink: 0;
`;

class DocumentHeader extends React.PureComponent {
  render() {
    const {
      title,
      multiLanguage,
      download,
      onSetDocumentHeader,
      readOnly
    } = this.props;

    return (
      <Root>
        <FaIcon left size="L" icon="file" />
        <StyledInput
          size="L"
          italic={title === "Neues Dokument-Template"}
          value={title}
          onChange={value => onSetDocumentHeader("title", value)}
          placeholder="Titel des Dokuments"
          inputType="text"
          noBorder={true}
          inputProps={{ width: "500px", "data-testid": "document-title" }}
        />
        <StyledViewOptions />
        {multiLanguage && <LanguageSelect />}
        {download && <StyledDownloadButton />}
        <StyledPreviewButton />
        {readOnly || <StyledSaveButton />}
      </Root>
    );
  }
}

export default connect(
  state => ({
    title: state.documentHeader.title,
    multiLanguage: state.feature.multiLanguage,
    download: state.feature.download
  }),
  dispatch => ({
    onSetDocumentHeader: (attr, value) =>
      dispatch(setDocumentHeader(attr, value))
  })
)(DocumentHeader);
