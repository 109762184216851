import React, { Component } from "react";
import styled from "@emotion/styled";

const StyledSpinner = styled("svg")`
  animation-duration: 0.5s;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 15px;
  width: 20px;
  margin: 0;

  @keyframes spin {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

class Spinner extends Component {
  render() {
    return (
      <StyledSpinner
        className={this.props.className}
        width="100px"
        height="100px"
        viewBox="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Page-1" stroke="none" fill="none">
          <g id="Artboard-2" fill={this.props.fill || "#333333"}>
            <path
              d="M1.77635684e-15,50 C1.77635684e-15,22.3857625 22.3857625,0 50,0 C77.6142375,0 100,22.3857625 100,50 C99.2717147,60.0084206 94.7848435,65.0126309 86.5393864,65.0126309 C78.2939292,65.0126309 73.7808005,60.0084206 73,50 C73,37.2974508 62.7025492,27 50,27 C37.2974508,27 27,37.2974508 27,50 C26.0969156,60.0084206 21.6456002,65.0126309 13.6460537,65.0126309 C5.64650713,65.0126309 1.09782258,60.0084206 1.77635684e-15,50 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </StyledSpinner>
    );
  }
}

export default Spinner;
