// @flow

import React from "react";
import styled from "@emotion/styled";

import { isEmpty, debounce } from "../../helpers";

import ClearZone from "./ClearZone";

const All = styled("div")`
  position: relative;
  width: ${({ inline, large }) =>
    large ? "100%" : inline ? "initial" : "100%"};
  display: ${({ inline }) => (inline ? "inline-block" : "block")};
`;

const SelectEl = styled("select")`
  color: #024761;
  background-color: #ffffff;
  font-weight: 700;
  outline-width: 0;
  padding: ${({ size }) =>
    size === "L" ? "12px 30px 12px 8px" : "8px 30px 8px 8px"};
  font-size: ${({ size }) => (size === "L" ? "20px" : "16px")};
  line-height: ${({ size }) => (size === "L" ? "20px" : "16px")};
  border-radius: 3px;
  border: ${({ noBorder }) => (noBorder ? "none" : "1px solid #ddd")};
  height: 34px;
  width: ${({ inline, large }) =>
    large ? "100%" : inline ? "initial" : "100%"};
  &::placeholder {
    color: #aaa;
  }
`;

type ValueType = ?(number | string);

type PropsType = {
  className?: string,
  size?: string,
  inputType: string,
  placeholder?: string,
  value: ValueType,
  inline?: boolean,
  clearable?: boolean,
  inputProps?: Object,
  className?: string,
  containerStyle?: Object,
  noBorder?: boolean,
  append?: any,
  onChange: (value: ValueType) => void
};

type StateType = {
  value: ?(number | string)
};

class Select extends React.Component<PropsType, StateType> {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { onChange, defaultValue } = this.props;

    if (!this.state.value && defaultValue) {
      this.setState({ value: defaultValue }, () => onChange(defaultValue));
    }
  }

  componentDidUpdate(prevProps) {
    // The value update came in from outside, not from within.
    // --> update the state accordingly.
    // (Introduced this, because document header is changed
    //   in Redux after mounting this input component)
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
      this.setState({ value: this.props.value });
    }
  }

  onChange(event) {
    const value = event.target.value;

    this.setState({ value: value }, () => this.props.onChange(value));
  }

  renderOptions() {
    return this.props.options.map(element => {
      return (
        <option key={element[0]} value={element[0]}>
          {element[1]}
        </option>
      );
    });
  }

  render() {
    const {
      value,
      inputType,
      containerStyle,
      inline,
      className,
      size,
      placeholder,
      noBorder,
      inputProps
    } = this.props;

    const large = !!value && typeof value === "string" && value.length > 20;

    return (
      <All inline={inline} large={large} style={containerStyle}>
        <SelectEl
          className={className}
          inline={inline}
          large={large}
          noBorder={noBorder}
          placeholder={placeholder}
          size={size}
          type={inputType}
          value={this.state.value === null ? "" : this.state.value}
          onChange={this.onChange}
          {...inputProps}
        >
          {this.renderOptions()}
        </SelectEl>
      </All>
    );
  }
}

export default Select;
