import React, { Component } from "react";

import ActiveLang from "../styled/ActiveLang";
import RichText from "../form/RichText";

import { placeholder } from "../../helpers/elementHelper";

const INIT = { forced_root_block: false };

class Text extends Component {
  onChange = value => {
    this.props.onChange("content", {
      ...this.props.element.atn.content,
      [this.props.settings.activeLang]: value
    });
  };

  render() {
    const {
      element,
      multiLanguage,
      settings: { activeLang }
    } = this.props;

    return (
      <RichText
        key={activeLang}
        value={
          element.atn.content[activeLang] ||
          placeholder(this.props, "content", "Textbaustein ohne Umbruch")
        }
        theId={element.id}
        onChange={this.onChange}
        init={INIT}
        placeholder="Kurztext als Baustein"
        append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
      />
    );
  }
}

export default Text;
