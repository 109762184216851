// @flow

import React, { Component } from "react";
import styled from "@emotion/styled";

import Input from "../form/Input";
import RequiredInput from "../form/RequiredInput";
import ActiveLang from "../styled/ActiveLang";
import FaIcon from "../main/FaIcon";

import { placeholder } from "../../helpers/elementHelper";

const Root = styled("div")``;

const Row = styled("div")`
  margin-bottom: 5px;
`;

const CameraArea = styled("div")`
  height: 50px;
  border: 2px dashed #ccc;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const CameraTitle = styled("h3")`
  margin: 0;
  font-size: 20px;
  color: #666;
`;

const StyledIcon = styled(FaIcon)`
  margin-right: 5px;
  font-size: 18px;
  color: #666;
`;

class CameraInput extends Component {
  onChangeAttr = (attr, value) => {
    this.props.onChange(attr, {
      ...this.props.element.atn[attr],
      [this.props.settings.activeLang]: value
    });
  };

  onChangeLabel = value => {
    this.onChangeAttr("label", value);
  };

  render() {
    const {
      element,
      onChange,
      multiLanguage,
      settings: { activeLang }
    } = this.props;

    return (
      <Root>
        <CameraArea>
          <CameraTitle>
            <StyledIcon icon="camera" />
            <span>Foto aufnehmen</span>
          </CameraTitle>
        </CameraArea>
        <Row>
          <Input
            key={activeLang}
            inline={true}
            clearable={true}
            value={element.atn.label[activeLang]}
            onChange={this.onChangeLabel}
            placeholder={placeholder(this.props, "label", "Bezeichnung")}
            inputType="text"
            append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
          />
        </Row>
        <RequiredInput
          activeLang={activeLang}
          multiLanguage={multiLanguage}
          onChange={onChange}
          required={element.atn.required}
          errorMessage={element.atn.errorMessage}
        />
      </Root>
    );
  }
}

export default CameraInput;
