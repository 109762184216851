// @flow

import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";

import { setElementStyleAttr } from "../../actions";
import { selectElementDisplayIndex, selectElement } from "../../selectors";

import Heading from "../elements-style/Heading";
import Separator from "../elements-style/Separator";
import Spacer from "../elements-style/Spacer";
import Paragraph from "../elements-style/Paragraph";
import List from "../elements-style/List";
import Image from "../elements-style/Image";
import Video from "../elements-style/Video";
import Signature from "../elements-style/Signature";
import TextInput from "../elements-style/TextInput";
import TextAreaInput from "../elements-style/TextAreaInput";
import SelectOne from "../elements-style/SelectOne";

import StyleEditorHeader from "../styled/StyleEditorHeader";
import IndexLabel from "../styled/IndexLabel";

const Row = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled("div")`
  padding: 0 14px;
`;

class StyleEditorElement extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.idx !== nextProps.idx ||
      // Small optimization, so that the style editor element does NOT update
      // when e.g. a "labelText" has been changed, since StyleEditorElement is only concerned about the "style"
      // Todo: replace this with a selector that specifically selects the style as a prop
      this.props.element.id !== nextProps.element.id ||
      !isEqual(this.props.element.atn.style, nextProps.element.atn.style)
    );
  }

  render() {
    // May happen when active element is deeply nested and some parent in its parent chain is deleted
    // A future alternative would be:
    // Update active element state depending on the elements that have been deleted.
    if (!this.props.element) return null;

    const {
      idx,
      element: {
        name,
        color,
        atn: { type }
      }
    } = this.props;

    return (
      <div>
        <StyleEditorHeader>
          <Row>
            <IndexLabel idx={idx} label={name} color={color} />
          </Row>
        </StyleEditorHeader>
        <Content>
          {(type === "group" || type === "text" || type === "cameraInput") && (
            <p>Keine weiteren Stile zu bearbeiten.</p>
          )}
          {type === "heading" && <Heading {...this.props} />}
          {type === "separator" && <Separator {...this.props} />}
          {type === "spacer" && <Spacer {...this.props} />}
          {type === "paragraph" && <Paragraph {...this.props} />}
          {type === "list" && <List {...this.props} />}
          {type === "image" && <Image {...this.props} />}
          {type === "video" && <Video {...this.props} />}
          {type === "textInput" && <TextInput {...this.props} />}
          {type === "textAreaInput" && <TextAreaInput {...this.props} />}
          {(type === "selectOne" ||
            type === "selectMultiple" ||
            type === "yesNo") && <SelectOne {...this.props} />}
          {type === "signature" && <Signature {...this.props} />}
        </Content>
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    idx: selectElementDisplayIndex(state, ownProps),
    element: selectElement(state, ownProps)
  }),
  (dispatch, ownProps) => ({
    onChange: (attr, value) =>
      dispatch(setElementStyleAttr(ownProps.elementId, attr, value))
  })
)(StyleEditorElement);
