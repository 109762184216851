// @flow

import React from "react";

import RadioButton from "../form/RadioButton";
import StylePicker from "./StylePicker";

type PropsType = {
  label: string,
  value: { size: string },
  onChange: (string, string) => void
};

const LinePicker = (props: PropsType) => {
  return (
    <StylePicker label={props.label}>
      <RadioButton
        value={props.value.line}
        options={[
          { label: "Normal", value: "wide" },
          { label: "Kurz", value: "short" },
          { label: "Punkte", value: "dots" }
        ]}
        onChange={value => props.onChange("line", value)}
      />
    </StylePicker>
  );
};

export default LinePicker;
