// @flow

import type { ATNValueType } from "../types";

import { initializeElement } from "../helpers/elementHelper";
import { compose, uuidv4, objectWithoutKey } from "../helpers";

import {
  NESTED_ELEMENT_KEYS,
  ELEMENT_DEFAULT_CHILDREN,
  NON_INTERACTIVE_TYPES,
} from "../atnConfig";

type ElementId = string;

export type BaseElementType = {
  id: ElementId,
  name: string,
  color: string,
  parentId?: string,
  defaultAtn: ATNValueType,
  staticElement: Boolean,
};

export type ElementType = BaseElementType & {
  atn: ATNValueType,
};

export type DisplayIndexType = {
  [ElementId]: number,
};
export type RootOrderType = ElementId[];
export type ElementsType = {
  [ElementId]: ElementType,
};

export type StateType = {
  displayIndex: DisplayIndexType,
  rootOrder: RootOrderType,
  data: ElementsType,
};

export const initialState: StateType = {
  displayIndex: {},
  rootOrder: [],
  data: {},
};

// --------------------
// A couple of helper functions
// --------------------
function addElToArray(arr, el, pos) {
  return [...arr.slice(0, pos), el, ...arr.slice(pos)];
}
function removeElFromArray(arr, el) {
  const idx = arr.indexOf(el);

  if (idx === -1) return arr;

  return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}
function setAttr(data, id, attr, value) {
  return {
    ...data,
    [id]: {
      ...data[id],
      [attr]: value,
    },
  };
}

const addElementToState = (element, { isChild } = {}) => (state) => {
  return {
    ...state,
    data: {
      ...state.data,
      [element.id]: element,
    },
    rootOrder:
      isChild || element.parentId
        ? state.rootOrder
        : [...state.rootOrder, element.id],
  };
};

function hasDefaultChildren(element) {
  return Object.keys(ELEMENT_DEFAULT_CHILDREN).includes(
    element.defaultAtn.type
  );
}

function addElementOfType(state, element, config = {}) {
  if (!hasDefaultChildren(element)) {
    return addElementToState(element, config)(state);
  }

  // So there are elements that have default children,
  // Like list / selectOne ...
  // Those children need to be added too
  const parent = element;
  const parentId = parent.id;

  const { nestingKey, children } = ELEMENT_DEFAULT_CHILDREN[
    element.defaultAtn.type
  ];

  const addChildren = children.map((childEl) => {
    return composeAddChild(parentId, childEl, nestingKey);
  });

  return compose(
    ...addChildren,
    addElementToState(parent)
  )(state);
}

function addElement(state, action) {
  const element = initializeElement(action.payload.element);

  return addElementOfType(state, element);
}

/*
  Basically a combination of addElementOfType and moveElement
  TODO: Refactor and use composition everywhere
*/
function addElementOfTypeAndMove(state, action) {
  const { element, targetId, below, nestingKey } = action.payload;
  const newElement = initializeElement(element);
  const sourceId = newElement.id;

  return moveElement(addElementOfType(state, newElement), {
    payload: {
      sourceId,
      targetId,
      below,
      nestingKey,
    },
  });
}

/*
  Basically a combination of addElementOfType and registerChild
  TODO: Refactor and use composition everywhere
*/
function addElementOfTypeAndRegisterChild(state, action) {
  const { parentId, element, nestingKey } = action.payload;
  const newElement = initializeElement(element);
  const childId = newElement.id;

  return registerChild({ payload: { parentId, childId, nestingKey } })(
    addElementOfType(state, newElement, { isChild: true })
  );
}

const removeElement = (action) => (state) => {
  const element = state.data[action.payload.id];

  const allRemoveFunctions = NESTED_ELEMENT_KEYS.reduce(
    (allFns, nestingKey) => {
      let fns = [...allFns];
      if (element.atn.hasOwnProperty(nestingKey)) {
        fns = fns.concat(
          element.atn[nestingKey].map((child) =>
            removeElement({ payload: { id: child.id } })
          )
        );
      }

      return fns;
    },
    []
  );
  const stateWithRemovedChildren = compose(...allRemoveFunctions)(state);

  const stateWithClearedChildren = element.parentId
    ? unregisterChild(stateWithRemovedChildren, {
        payload: {
          parentId: element.parentId,
          childId: action.payload.id,
        },
      })
    : stateWithRemovedChildren;

  return {
    data: objectWithoutKey(element.id)(stateWithClearedChildren.data),
    rootOrder: element.parentId
      ? stateWithClearedChildren.rootOrder
      : removeElFromArray(stateWithClearedChildren.rootOrder, element.id),
  };
};

/*
  Helper function for copying elements
*/
const justCopy = (element, parentId) => {
  const newEl = {
    ...JSON.parse(JSON.stringify(element)), // Deep copy
    id: uuidv4(),
  };

  if (parentId) {
    newEl.parentId = parentId;
  }

  return newEl;
};

/*
  Recursively copy an element.

  @param state – the state the find elements from
  @param id – the id of the element to copy
  @param parentId – the parentId of the element to copy
  @param elementsToAddInitial – collect elements to add to state during recursion

  @returns {
    copiedElement – the root element copied
    elementsToAdd – the growing collection of elements to add to the state in the end
  }
*/
const recursivelyCopyElement = (state, id, parentId, elementsToAddInitial) => {
  const element = state.data[id];

  const copiedElement = justCopy(element, parentId);
  let elementsToAdd = [...elementsToAddInitial];

  NESTED_ELEMENT_KEYS.forEach((nestingKey) => {
    if (copiedElement.atn.hasOwnProperty(nestingKey)) {
      const copiedChildren = copiedElement.atn[nestingKey].map((child) =>
        recursivelyCopyElement(state, child.id, copiedElement.id, elementsToAdd)
      );

      // Let's check I didn't add it before. I'm too stupid to be efficient here :-/
      for (const copiedChild of copiedChildren) {
        for (const nestedElement of copiedChild.elementsToAdd) {
          if (!elementsToAdd.find((el) => el.id === nestedElement.id)) {
            elementsToAdd.push(nestedElement);
          }
        }
      }

      copiedElement.atn[nestingKey] = copiedChildren.map((child) => ({
        id: child.copiedElement.id,
      }));
    }
  });

  elementsToAdd = elementsToAdd.concat(copiedElement);

  return {
    copiedElement,
    elementsToAdd,
  };
};

const copyElement = (action) => (state) => {
  const el = state.data[action.payload.id];

  const { copiedElement, elementsToAdd } = recursivelyCopyElement(
    state,
    action.payload.id,
    null,
    []
  );

  let nextState = state;

  // Just in case the top level element to copy was nested,
  // we'll need to add it to the parent's children
  if (el.parentId) {
    // TODO: Replace with some immutableJS assignment
    const children = nextState.data[el.parentId].atn[action.payload.nestingKey];
    const elPos = children.indexOf(children.find((ch) => ch.id === el.id));

    nextState = {
      ...nextState,
      data: {
        ...nextState.data,
        [el.parentId]: {
          ...nextState.data[el.parentId],
          atn: {
            ...nextState.data[el.parentId].atn,
            [action.payload.nestingKey]: addElToArray(
              children,
              { id: copiedElement.id },
              elPos + 1
            ),
          },
        },
      },
    };
  } else {
    nextState = {
      ...nextState,
      rootOrder: addElToArray(
        nextState.rootOrder,
        copiedElement.id,
        nextState.rootOrder.indexOf(el.id) + 1
      ),
    };
  }

  // And then finally add all the collected copied elements to the state in one go
  return {
    ...nextState,
    data: {
      ...nextState.data,
      ...elementsToAdd.reduce((all, el) => {
        all[el.id] = el;

        return all;
      }, {}),
    },
  };
};

function moveElement(state, action) {
  const { sourceId, targetId, below, nestingKey } = action.payload;

  const source = state.data[sourceId];
  const target = state.data[targetId];

  // Potentially: Unregister source from a current parent
  let nextState = source.parentId
    ? unregisterChild(state, {
        payload: {
          parentId: source.parentId,
          childId: sourceId,
        },
      })
    : {
        ...state,
        rootOrder: removeElFromArray(state.rootOrder, sourceId),
      };

  // Potentially: Register source with new parent
  if (target.parentId) {
    const children = nextState.data[target.parentId].atn[nestingKey];
    const newNestingIdx = children.indexOf(
      children.find((child) => child.id === targetId)
    );
    const nestingIdx = below ? newNestingIdx + 1 : newNestingIdx;

    nextState = registerChild({
      payload: {
        parentId: target.parentId,
        childId: sourceId,
        nestingKey,
        nestingIdx,
      },
    })(state);
  } else {
    const newTargetIdx = nextState.rootOrder.indexOf(targetId);
    const pos = below ? newTargetIdx + 1 : newTargetIdx;

    nextState = {
      ...nextState,
      rootOrder: addElToArray(nextState.rootOrder, sourceId, pos),
    };
  }

  return nextState;
}

function removeKey(obj, deleteKey) {
  return Object.keys(obj)
    .filter((key) => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = obj[current];
      return result;
    }, {});
}

function setElementAttr(data, action) {
  const { id, attr, value } = action.payload;

  return setAttr(data, id, "atn", {
    ...data[id].atn,
    [attr]: value,
  });
}

function removeElementAttr(data, action) {
  const { id, attr } = action.payload;

  return setAttr(data, id, "atn", removeKey(data[id].atn, attr));
}

function setElementStyleAttr(data, action) {
  const { id, attr, value } = action.payload;

  return setAttr(data, id, "atn", {
    ...data[id].atn,
    style: {
      ...(data[id].atn.style || {}),
      [attr]: value,
    },
  });
}

function isItsOwnParent(data, parentId, childId) {
  if (!parentId) {
    return false;
  }

  if (parentId === childId) {
    return true;
  }

  const nextParentId = data[parentId].parentId;

  return isItsOwnParent(data, nextParentId, childId);
}

function isRegisterChildPossible(
  state,
  parentId,
  childId,
  putsChildToSpecificPosition
) {
  const isChildOfParentAlready = state.data[childId].parentId === parentId;

  return (
    (!isChildOfParentAlready || putsChildToSpecificPosition) &&
    !isItsOwnParent(state.data, parentId, childId) // To prevent that someone moves a parent into its children
  );
}

const registerChild = (action) => (state) => {
  const { parentId, childId, nestingKey, nestingIdx } = action.payload;
  const putsChildToSpecificPosition = typeof nestingIdx !== "undefined";

  if (
    !isRegisterChildPossible(
      state,
      parentId,
      childId,
      putsChildToSpecificPosition
    )
  ) {
    return state;
  }

  const clearedState = state.data[childId].parentId
    ? unregisterChild(state, {
        payload: {
          childId,
          parentId: state.data[childId].parentId,
        },
      })
    : {
        ...state,
        rootOrder: removeElFromArray(state.rootOrder, childId),
      };

  const clearedParent = clearedState.data[parentId];
  const childPosition = putsChildToSpecificPosition
    ? nestingIdx
    : clearedParent.atn[nestingKey].length;

  const parentWithChild = {
    ...clearedParent,
    atn: {
      ...clearedParent.atn,
      [nestingKey]: addElToArray(
        clearedParent.atn[nestingKey],
        { id: childId },
        childPosition
      ),
    },
  };

  clearedState.data[parentId] = parentWithChild;

  return {
    ...clearedState,
    data: setAttr(
      clearedState.data,
      childId,
      "parentId",
      action.payload.parentId
    ),
  };
};

function unregisterChild(state, action) {
  const { parentId, childId } = action.payload;

  const parent = state.data[parentId];

  const nestingKey = NESTED_ELEMENT_KEYS.find(
    (key) =>
      parent.atn[key] && !!parent.atn[key].find((ch) => ch.id === childId)
  );

  const childObj = parent.atn[nestingKey].find((el) => el.id === childId);

  const parentWithoutChild = {
    ...parent,
    atn: {
      ...parent.atn,
      [nestingKey]: removeElFromArray(parent.atn[nestingKey], childObj),
    },
  };

  const dataWithNewParent = {
    ...state.data,
    [parentId]: parentWithoutChild,
  };

  return {
    ...state,
    data: setAttr(dataWithNewParent, childId, "parentId", null),
  };
}

/*
  Adding a child means adding the element and registering the children relationship
*/
function composeAddChild(parentId, child, nestingKey) {
  const initializedChildEl = initializeElement(child);

  return compose(
    registerChild({
      payload: { parentId, childId: initializedChildEl.id, nestingKey },
    }),
    addElementToState(initializedChildEl, { isChild: true })
  );
}

function addChild(state, action) {
  const { parentId, child, nestingKey } = action.payload;

  return composeAddChild(parentId, child, nestingKey)(state);
}

function recalcIndex(state) {
  const displayIndex = {};
  const rootElements = state.rootOrder
    .filter((id) => {
      return (
        !state.data[id].parentId &&
        !NON_INTERACTIVE_TYPES.includes(state.data[id].atn.type)
      );
    })
    .map((id) => state.data[id]);

  let idx = 1;
  let toVisit = [...rootElements];

  while (toVisit.length > 0) {
    const node = toVisit.shift();

    displayIndex[node.id] = idx;

    // Don't count "options" and so on
    if (!NON_INTERACTIVE_TYPES.includes(node.atn.type)) {
      idx++;
    }

    const children = NESTED_ELEMENT_KEYS.reduce((all, key) => {
      if (node.atn.hasOwnProperty(key)) {
        all = all.concat(
          node.atn[key].map((child) => state.data[child.id]).filter((v) => !!v)
        );
      }

      return all;
    }, []);

    toVisit = children.concat(toVisit);
  }

  return { ...state, displayIndex };
}

function addStaticElement(action, state) {
  const idsInState = Object.keys(state.data);
  const staticRootId = action.payload.rootOrder[0];

  if (idsInState.includes(staticRootId)) {
    return { ...state };
  }

  return {
    ...state,
    data: { ...state.data, ...action.payload.elements },
    rootOrder: [...state.rootOrder, ...action.payload.rootOrder],
  };
}

function reducer(state: StateType = initialState, action: Object): StateType {
  switch (action.type) {
    case "ELEMENT_SET_ATTR":
      return { ...state, data: setElementAttr(state.data, action) };
    case "ELEMENT_REMOVE_ATTR":
      return { ...state, data: removeElementAttr(state.data, action) };
    case "ELEMENT_SET_STYLE_ATTR":
      return {
        ...state,
        data: setElementStyleAttr(state.data, action),
      };
    case "ELEMENTS_REPLACE":
      return recalcIndex({
        ...state,
        data: action.payload.elements,
        rootOrder: action.payload.rootOrder,
      });
    case "ELEMENT_ADD":
      return recalcIndex(addElement(state, action));
    case "ELEMENT_ADD_AND_MOVE":
      return recalcIndex(addElementOfTypeAndMove(state, action));
    case "ELEMENT_ADD_AND_REGISTER_CHILD":
      return recalcIndex(addElementOfTypeAndRegisterChild(state, action));
    case "ELEMENT_REMOVE":
      return recalcIndex(removeElement(action)(state));
    case "ELEMENT_COPY":
      return recalcIndex(copyElement(action)(state));
    case "ELEMENT_MOVE":
      return recalcIndex(moveElement(state, action));
    case "ELEMENT_ADD_CHILD":
      return recalcIndex(addChild(state, action));
    case "ELEMENT_REGISTER_CHILD":
      return recalcIndex(registerChild(action)(state));
    case "STATIC_ELEMENT_ADD":
      return recalcIndex(addStaticElement(action, state));
    default:
      return state;
  }
}

export default reducer;
