// @flow

import { createSelector } from "reselect";

import type { ElementsType } from "./reducers/elementsReducer";

// ---------------------
// Accessors
// ---------------------
const getElements = state => state.elements.data;
const getDisplayIndex = state => state.elements.displayIndex;
const getActiveElementId = state => state.activeElement.id;
const getViewCondensed = state => state.view.condensed;
const getUnCondensedElementId = state => state.view.unCondensedElementId;

const propsElementId = (_, props) => props.elementId;
const propsNestingKey = (_, props) => props.nestingKey;

export const getRootOrder = state => state.elements.rootOrder;

// ---------------------
// Selectors, using reselect. Results are memoized
// ---------------------

/*
  Mainly used for building the entire ATN in the end

  It's important to get all elements and to get the right order of
  Element not directly attached to root will be in the front (index = -1)
  But their order is not important.
*/
export const selectElementsInOrder = createSelector(
  getElements,
  getRootOrder,
  (elements, rootOrder) => {
    return Object.keys(elements)
      .sort((a, b) => rootOrder.indexOf(a) - rootOrder.indexOf(b))
      .map(id => elements[id]);
  }
);

export const selectElement = createSelector(
  getElements,
  propsElementId,
  (elements, elementId) => elements[elementId]
);

export const selectActiveElement = createSelector(
  getElements,
  getActiveElementId,
  (elements, activeElementId) => elements[activeElementId]
);

export const makeSelectNestedElementIds = () =>
  createSelector(
    getElements,
    propsElementId,
    propsNestingKey,
    (elements, elementId, nestingKey) => {
      if (!elements[elementId].atn[nestingKey]) return [];

      return elements[elementId].atn[nestingKey].map(child => child.id);
    }
  );

export const selectElementDisplayIndex = createSelector(
  getDisplayIndex,
  propsElementId,
  (displayIndex, elementId) => displayIndex[elementId]
);

export const selectElementCondensed = createSelector(
  getElements,
  propsElementId,
  getViewCondensed,
  getUnCondensedElementId,
  (elements, elementId, condensed, unCondensedElementId) =>
    condensed &&
    !elements[elementId].parentId &&
    unCondensedElementId !== elementId
);

/*
  Having (potentially translatable) messages in the selectors isn't ideal.
  In the future, when an i18n library is used, these should go to a central place.
*/
export const selectMessage = state => {
  if (state.documentStatus.docSave.success) {
    return "Speichern erfolgreich.";
  }
  if (state.documentStatus.docSave.error) {
    return "Es ist ein Fehler aufgetreten. Bitte noch einmal versuchen.";
  }

  return null;
};
