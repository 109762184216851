import React, { Component } from "react";
import styled from "@emotion/styled";

import Input from "../form/Input";
import RequiredInput from "../form/RequiredInput";
import ActiveLang from "../styled/ActiveLang";

import { placeholder } from "../../helpers/elementHelper";

const Root = styled("div")`
  width: 100%;
`;

const SignatureArea = styled("div")`
  height: 70px;
  border: 2px dashed #ccc;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const SignatureTitle = styled("h3")`
  margin: 0;
  font-size: 20px;
  color: #666;
`;

const StyledRequiredInput = styled(RequiredInput)`
  margin-top: 5px;
`;

const INPUT_PROPS = { full: true };

class Paragraph extends Component {
  onChange = value => {
    this.props.onChange("caption", {
      ...this.props.element.atn.caption,
      [this.props.settings.activeLang]: value
    });
  };

  render() {
    const {
      element,
      onChange,
      multiLanguage,
      settings: { activeLang }
    } = this.props;

    return (
      <Root>
        <SignatureArea>
          <SignatureTitle>Unterschrift-Feld</SignatureTitle>
        </SignatureArea>
        <Input
          key={activeLang}
          clearable={true}
          inline={true}
          value={element.atn.caption[activeLang]}
          onChange={this.onChange}
          inputProps={INPUT_PROPS}
          placeholder={placeholder(this.props, "caption", "Wer unterschreibt?")}
          inputType="text"
          append={multiLanguage && <ActiveLang>{activeLang}</ActiveLang>}
        />
        <StyledRequiredInput
          activeLang={activeLang}
          multiLanguage={multiLanguage}
          onChange={onChange}
          required={element.atn.required}
          errorMessage={element.atn.errorMessage}
        />
      </Root>
    );
  }
}

export default Paragraph;
