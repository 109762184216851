// @flow

export type StateType = {
  csrfToken: ?string
};

export const initialState: StateType = {
  csrfToken: null
};

function reducer(state: StateType = initialState, action: Object): StateType {
  switch (action.type) {
    case "META_SET":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default reducer;
