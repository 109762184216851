import React from "react";
import { DragDropContextProvider } from "react-dnd";
import { HTML5Backend }from "react-dnd-html5-backend";

export default ({ children, className }) => (
  <div className={className}>
    <DragDropContextProvider backend={HTML5Backend}>
      {children}
    </DragDropContextProvider>
  </div>
);
