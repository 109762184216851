// @flow

import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";

import { makeSelectNestedElementIds, selectElement } from "../../selectors";
import { addChild } from "../../actions";

import Input from "../form/Input";
import IconButton from "../form/IconButton";
import RequiredInput from "../form/RequiredInput";
import ActiveLang from "../styled/ActiveLang";
import FaIcon from "../main/FaIcon";

import NestedOption from "./NestedOption";

import { placeholder } from "../../helpers/elementHelper";

const Root = styled("div")`
  width: 100%;
`;

const Options = styled("div")``;

const Row = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 2px 0;
`;

class ElementWithNesting extends Component {
  getPlaceholder = (i) => {
    const { optionPlaceholders, optionPlaceholder } = this.props;

    return optionPlaceholders && optionPlaceholders[i]
      ? optionPlaceholders[i]
      : `${optionPlaceholder} ${i + 1}`;
  };

  getDefaultValue = (i) => {
    const { defaultValues } = this.props;

    return defaultValues ? defaultValues[i] : null;
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  onChangeLabel = (value) => {
    this.props.onChange("label", {
      ...this.props.element.atn.label,
      [this.props.settings.activeLang]: value,
    });
  };

  render() {
    const {
      element,
      children,
      onChange,
      onAddChild,
      hasNoLabel,
      nestedContentKey,
      multiLanguage,
      allowsFurtherNesting,
      onMountNotify,
      activeOnMount,
      onScrollToMe,
      settings,
      nestingIndicator,
      labelPlaceholder,
      staticElement,
    } = this.props;

    return (
      <Root>
        {!hasNoLabel && (
          <Row>
            <Input
              clearable={!staticElement}
              key={settings.activeLang}
              value={element.atn.label[settings.activeLang]}
              onChange={this.onChangeLabel}
              placeholder={placeholder(this.props, "label", labelPlaceholder)}
              inputType="text"
              append={
                multiLanguage && <ActiveLang>{settings.activeLang}</ActiveLang>
              }
              staticElement={staticElement}
            />
          </Row>
        )}
        <Options>
          {children.map((id, i) => {
            const placeholder = this.getPlaceholder(i);
            const defaultValue = this.getDefaultValue(i);

            return (
              <NestedOption
                key={id}
                idx={i}
                elementId={id}
                isRemovable={children.length > 1}
                activeLang={settings.activeLang}
                multiLanguage={multiLanguage}
                placeholder={placeholder}
                contentKey={nestedContentKey}
                defaultValue={defaultValue}
                nestingKey="children"
                nestingIndicator={nestingIndicator}
                allowsFurtherNesting={allowsFurtherNesting}
                onMountNotify={onMountNotify}
                activeOnMount={activeOnMount}
                settings={settings}
                onScrollToMe={onScrollToMe}
                staticElement={staticElement}
              />
            );
          })}
        </Options>
        {!staticElement && (
          <Row>
            <IconButton tabIndex="-1" onClick={onAddChild}>
              <FaIcon left icon="plus" />
              Weiteres Element hinzufügen
            </IconButton>
          </Row>
        )}
        {allowsFurtherNesting && !staticElement && (
          <RequiredInput
            activeLang={settings.activeLang}
            multiLanguage={multiLanguage}
            onChange={onChange}
            required={element.atn.required}
            errorMessage={element.atn.errorMessage}
          />
        )}
      </Root>
    );
  }
}

const makeMapStateToProps = () => {
  const selectNestedElementsIds = makeSelectNestedElementIds();

  return (state, ownProps) => ({
    element: selectElement(state, ownProps),
    children: selectNestedElementsIds(state, ownProps),
  });
};

export default connect(
  makeMapStateToProps,
  (dispatch, ownProps) => ({
    onAddChild: () =>
      dispatch(
        addChild(
          ownProps.element.id,
          ownProps.nestingElement,
          ownProps.nestingKey
        )
      ),
  })
)(ElementWithNesting);
