// @flow

import * as React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import Hotkeys from "react-hot-keys";
import { Helmet } from "react-helmet";

import { setActiveElement, loadDocumentTemplate } from "../../actions";

const Root = styled("div")`
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

type PropsType = {
  csrfToken?: string,
  onDeselect: () => void,
  children?: React.Node,
  onLoadDocumentTemplate: () => void
};

class Document extends React.PureComponent<PropsType> {
  componentDidMount() {
    this.props.onLoadDocumentTemplate();
  }

  render() {
    return (
      <Root>
        <Helmet>
          <meta name="csrf-token" content={this.props.csrfToken} />
        </Helmet>

        <Hotkeys keyName="ESC" onKeyUp={this.props.onDeselect} />
        {this.props.children}
      </Root>
    );
  }
}

export default connect(
  state => ({
    csrfToken: state.meta.csrfToken
  }),
  dispatch => ({
    onDeselect: () => dispatch(setActiveElement(null)),
    onLoadDocumentTemplate: () => dispatch(loadDocumentTemplate())
  })
)(Document);
