import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { DropTarget } from "react-dnd";

import { addElement, addStaticElement } from "../../actions";
import { loadATN } from "../../helpers/atnLoader";
import {
  isStaticElement,
  isInitializedElement,
} from "../../helpers/elementHelper";

const Root = styled("div")`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  background-color: ${({ isOver }) => (isOver ? "#fafafa" : "white")};
`;

const documentBodyTarget = {
  canDrop(props, monitor) {
    // Use potentially:
    //
    // const item = monitor.getItem();
    //
    // item.fromPosition, props.position;
    return true;
  },

  hover(props, monitor, component) {
    // This is fired very often and lets you perform side effects
    // in response to the hover. You can't handle enter and leave
    // here—if you need them, put monitor.isOver() into collect() so you
    // can just use componentDidUpdate() to handle enter/leave.
    // You can access the coordinates if you need them
    // const clientOffset = monitor.getClientOffset();
    // const componentRect = findDOMNode(component).getBoundingClientRect();
    // You can check whether we're over a nested drop target
    // const isJustOverThisOne = monitor.isOver({ shallow: true });
    // You will receive hover() even for items for which canDrop() is false
    // const canDrop = monitor.canDrop();
  },

  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return;
    }

    // Obtain the dragged item
    const dragElement = monitor.getItem();

    if (isInitializedElement(dragElement.element)) return;

    if (isStaticElement(dragElement) || dragElement.element.static) {
      props.addStaticElement(dragElement.element);
    } else {
      props.addElement(dragElement.element);
    }

    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    return { moved: true };
  },
};

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}

class DocumentBody extends Component {
  render() {
    const { className, children, connectDropTarget, isOver } = this.props;

    return (
      <Root
        isOver={isOver}
        ref={(instance) => connectDropTarget(instance)}
        className={className}
      >
        {children}
      </Root>
    );
  }
}

export default connect(
  () => ({}),
  (dispatch) => ({
    addElement: (element) => {
      dispatch(addElement(element));
    },
    addStaticElement: (element) => {
      const { elements, rootOrder } = loadATN(element.atn);
      dispatch(addStaticElement(elements, rootOrder));
    },
  })
)(DropTarget("EDITOR_ELEMENT", documentBodyTarget, collect)(DocumentBody));
