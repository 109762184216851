import React from "react";
import styled from "@emotion/styled";

import AlignmentPicker from "../style-form/AlignmentPicker";
import SizePicker from "../style-form/SizePicker";

const Root = styled("div")`
  margin: 0;
`;

const OPTIONS = [
  { label: "Original", value: "original" },
  { label: "S", value: "s" },
  { label: "M", value: "m" },
  { label: "L", value: "l" }
];

const Image = props => {
  return (
    <Root>
      <AlignmentPicker
        label="Positionierung"
        value={props.element.atn.style || {}}
        onChange={props.onChange}
      />
      <SizePicker
        label="Breite"
        value={props.element.atn.style || {}}
        options={OPTIONS}
        onChange={props.onChange}
      />
    </Root>
  );
};

export default Image;
